import React, { PureComponent } from 'react';
import { Col, Card } from 'reactstrap';
import LoadingIcon from '../../../../shared/LoadingIcon';
let srcDoc = window.location.hostname === 'intelligence.stelter.com' ? 'https://docs.google.com/document/d/1M6cZ5M98qMKYpYWP1Pe77DbI2CLyTNqbmUYxK0pc77o/preview' : 'https://docs.google.com/document/d/1h1uZ-JP2k3IcSxc834qtctabhlk91B24TBZHhcWhKWY/preview';

class MainCard extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      iframeHeight: '100%',
      containerHeight: 'calc(100vh - 150px)'
    }
    this.containerRef = React.createRef();
  }
    
  componentDidMount = async () => {
    const position = this.containerRef.current.getBoundingClientRect();
    const topPosition = position.top;
    this.setState({
      loading: false, 
      loaded: true,
      containerHeight: `calc(100vh - ${topPosition}px)`
    });
  }

  render(){
    return (
      <Col md={12}>
        <Card>
            <LoadingIcon />
            <div id="iframe-container" className="iframe-container" ref={this.containerRef} style={{height: this.state.containerHeight}}>
              <div className="iframe-wrapper" style={{height: this.state.iframeHeight}}>
                {this.state.loaded ? (
                  <iframe className="iframe__release-notes" title="Release Notes embed" id="iframe" src={srcDoc} width="100%" height="100%" scrolling="yes"></iframe>
                ) : null}
              </div>
            </div>
        </Card>
      </Col>
    )
  }
};

export default MainCard;
