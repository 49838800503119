import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Content from './components/ReleaseNotesContent';

const Page = () => {

  return (
    <div className="container__wrap">
      <Container className="dashboard">
        <Row className="row__dashboard-intro">
          <Col md={12}>
            <h3 className="page-title">Release Notes</h3>
          </Col>
        </Row>
        <Row>
          <Content />
        </Row>
      </Container>
    </div>
  )
  
};

export default Page;
