import React, { PureComponent } from 'react';
import { store } from '../../../App/store';
import { Card, Col } from 'reactstrap';
import LoadingIcon from '../../../../shared/LoadingIcon';
import { fetchEmbed } from '../../../../utils/helpers';
const embedItem = 21;
let srcDoc = '';

class Content extends PureComponent {
  static contextType = store;

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      iframeHeight: '100%',
      containerHeight: 'calc(100vh - 150px)',
      demo: false,
      query: null
    }
    this.containerRef = React.createRef();
  }

  componentDidMount = async () => {
    const globalState = this.context;
    const { userGroup, accountID } = globalState.state;
    const isAdmin = userGroup === 'Admin';
    const position = this.containerRef.current !== null ? this.containerRef.current.getBoundingClientRect() : {top: 140};
    const topPosition = position.top;

    const queryParams = new URLSearchParams(this.props.location.search);

    this.setState({
      loading: true,
      containerHeight: `calc(100vh - ${topPosition}px)`,
      query: queryParams
    });

    let pFilters = '';
    let pFilterArr = [{
      column:"Account ID",
      operand: "IN",
      values: [accountID]
    }];
    let weekNumber = '';

    if(queryParams.get('week') !== null) {
      const today = new Date();
      weekNumber = 'WK' + queryParams.get('week') + ' ' + today.getFullYear();
      pFilterArr.push({
        column: "CompletedWeek Formatted",
        operand: "IN",
        values: [weekNumber]
      })
    }

    if (queryParams.get('group') !== null) {
      let groupParam = queryParams.get('group').toLowerCase();
      let groupArr = [];
      if (groupParam.indexOf('a') > -1) {
        groupArr.push("A. Requested Information")
      }
      if (groupParam.indexOf('b') > -1) {
        groupArr.push("B. New or Confirmed Planned Gift")
      }
      if (groupParam.indexOf('c') > -1) {
        groupArr.push("C. Very Likely to Consider a Planned Gift")
      }
      if (groupParam.indexOf('d') > -1) {
        groupArr.push("D. Might Consider a Planned Gift")
      }
      if (groupParam.indexOf('e') > -1) {
        groupArr.push("E. Has PG Indicators")
      }
      if (groupParam.indexOf('f') > -1) {
        groupArr.push("F. Not a Prospect")
      }

      pFilterArr.push({
        column: "Lead Group Name",
        operand: "IN",
        values: groupArr
      })
    } else {
      if(accountID !== 'A0014941') {
        pFilterArr.push({
          column: "Lead Group Name",
          operand: "IN",
          values: ["A. Requested Information"]
        })
      } else {
        pFilterArr.push({
          "column": "RequestToFulfill",
          "operand": "IN",
          "values": ["Request to Fulfill", "Stelter Fulfilled"]
        })
      }
    }

    if(pFilterArr.length > 0) {
      pFilters = JSON.stringify(pFilterArr);
    }

    if (isAdmin) console.log('pFilters = '+ pFilters)

    this.fetchEmbed = fetchEmbed.bind(this);
    srcDoc = await this.fetchEmbed(embedItem, pFilters, isAdmin);

    this.setState({ // prevState - previous state 
      loading: false, 
      loaded: true,
      week: weekNumber
    });

    // style={{height: this.state.iframeHeight}}
  }

  render(){
    const globalState = this.context;
    const { accountName, customSurveyClient } = globalState.state;
    const demo = accountName === 'Utopia University';
    const customSurveyAccess = customSurveyClient.toString() === "true";
    
    return (
      <Col md={12}>
          {(demo || customSurveyAccess) ? (
              <Card>
                <LoadingIcon />
                <div id="iframe-container" className="iframe-container" ref={this.containerRef} style={{height: this.state.containerHeight}}>
                  <div className="iframe-wrapper" >
                    {this.state.loaded ? (
                      <iframe className="iframe__survey" title="Survey requests embed" id="iframe" src="" srcDoc={srcDoc} width="100%" height="100%" scrolling="no"></iframe>
                    ) : null}
                  </div>
                </div>
              </Card>
            ) : 
              <Card>
                <div id="iframe-container" className="iframe-container" style={{height: '300px'}}>
                  <div className="iframe-wrapper" style={{height: '300px'}}>
                    <p style={{paddingLeft: '8px'}}>Survey results coming soon!</p>
                  </div>
                </div>
              </Card>
            }
      </Col>
    )
}}
  
  export default Content;
