import React, { useContext } from 'react';
import { store } from '../../App/store';
import { Col, Container, Row } from 'reactstrap';

import Content from './components/GlossaryContent';


const Page = () => {
  const globalState = useContext(store);
  const { pgWebsite } = globalState.state;

  return (
    <div className="container__wrap">
      <Container className="dashboard">
        <Row className="row__dashboard-intro">
          <Col md={12}>
            <h3 className="page-title">Glossary</h3>
          </Col>
        </Row>
        <Row>
          <Content pgwebsite={pgWebsite} />
        </Row>
      </Container>
    </div>
  )
  
};

export default Page;
