import React, { PureComponent } from 'react';
import { store } from '../../../App/store';
import { Card, CardBody, Row, Col } from "reactstrap";
// import { Card, Col } from 'reactstrap';
// import LoadingIcon from '../../../../shared/LoadingIcon';
import { listFiles, downloadFile } from '../../../../utils/helpers';

import FolderOutlineIcon from 'mdi-react/FolderOutlineIcon';
import FileDocumentOutlineIcon from 'mdi-react/FileDocumentOutlineIcon';
import FilePdfOutlineIcon from 'mdi-react/FilePdfOutlineIcon';
import FileImageOutlineIcon from 'mdi-react/FileImageOutlineIcon';
import FileTableOutlineIcon from 'mdi-react/FileTableOutlineIcon';
import ZipBoxOutlineIcon from 'mdi-react/ZipBoxOutlineIcon';

class Content extends PureComponent {
  static contextType = store;

  constructor(){
    super();
    this.state = {
      loading: false,
      loaded: false,
      iframeHeight: '100%',
      containerHeight: 'calc(100vh - 150px)',
      folders: '',
      files: '',
      filepath: ''
    }
    this.containerRef = React.createRef();
  }

  componentDidMount = async () => {
    const globalState = this.context;
    const { accountID } = globalState.state;
    const { filepath } = this.state;
    // const isAdmin = userGroup === 'Admin';
    // const position = this.containerRef.current.getBoundingClientRect();
    // const topPosition = position.top;
    // this.setState({
    //   loading: true,
    //   containerHeight: `calc(100vh - ${topPosition}px)`
    // });

    this.listFiles = listFiles.bind(this);
    var filesFolders = await this.listFiles(accountID, filepath);
    //if (isAdmin) console.log(embedId)


    this.setState({ // prevState - previous state 
      loading: false, 
      loaded: true,
      folders: filesFolders.folders,
      files: filesFolders.files
    });
  }

  setFilePath = async (folder) => {
    const globalState = this.context;
    const { accountID } = globalState.state;
    var filesFolders = await this.listFiles(accountID, folder);
    this.setState({ // prevState - previous state 
      folders: filesFolders.folders,
      files: filesFolders.files,
      filepath: folder
    });
  }

  getFile = async (file) => {
    const globalState = this.context;
    const { accountID } = globalState.state;
    this.downloadFile = downloadFile.bind(this); 
    await this.downloadFile(accountID, file);
  }

  render(){
    const { folders, files, filepath } = this.state;
    var filepathButtons = filepath.split("/").filter(folder => Boolean(folder));
    let foldersLength = filepathButtons.length;

    return (
      
      <div className="files-container">
        <Row className='full-width'>
          <Col md={12}>
            <Card className="top-margin auto-height">
              <CardBody className="card-lg">
                <Row>
                  <Col md={6}>
                    <nav className='folder-nav'><span className='folder-nav-item' onClick={() => this.setFilePath('')}>Root Folder</span>

                    {filepathButtons.length > 0 &&
                      filepathButtons.map((btn, i) => {
                          var folderPath = filepath.split(btn)[0] + btn + '/';
                          if (foldersLength === i + 1) {
                            return <span className='folder-nav-item folder-nav-item__current' key={btn + '_' + i} ><span className='folder-nav-separator'>/</span>{btn}</span>
                          } else {
                            return <span className='folder-nav-item' key={btn + '_' + i} onClick={() => this.setFilePath(folderPath)}><span className='folder-nav-separator'>/</span>{btn}</span>
                          }
                      })
                    }</nav>
                  </Col>
                  <Col md={6}>
                    <p>Upload | Download | Etc.</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='full-width'>
          <Col md={12}>
            <Card className="top-margin auto-height">
              <CardBody className="card-lg">
                  <ul className='files-folders-list'>
                    {folders !== '' &&
                      folders.map((folder, i) => {
                        return <li className='folder-item mdi-folder-outline' key={`folder_${i}`} onClick={()=>this.setFilePath(folder.path)}><FolderOutlineIcon /> <span className='folder-info'>{folder.name}</span></li>
                      })
                    }
                    {files.length > 0 &&
                      files.map((file, i) => {
                        var fileType = file.type;
                        var fileIcon = '';
                        if (fileType === 'pdf') {
                          fileIcon = <FilePdfOutlineIcon />
                        } else if (fileType === 'spreadsheet') {
                          fileIcon = <FileTableOutlineIcon />
                        } else if (fileType === 'image') {
                          fileIcon = <FileImageOutlineIcon />
                        } else if (fileType === 'zip') {
                          fileIcon = <ZipBoxOutlineIcon />
                        } else {
                          fileIcon = <FileDocumentOutlineIcon />
                        }
                        return <li className={'file-item file-item__'+fileType} key={`file_${i}`}>{fileIcon} <span className='file-info'><span className='file-info__name' onClick={() => this.getFile(file.path)}>{file.name}</span><span className='file-info__details'>{file.lastModified} &bull; {file.filesize}</span></span></li>
                      })
                    }
                    {files.length === 0 &&
                      <li className={'file-item file-item__empty'} key='file_empty'>...</li>
                    }
                  </ul>
              </CardBody>
            </Card>
          </Col>
        </Row> 
        {/* <LoadingIcon /> */}
        {/* <div id="files-widget" ref={this.containerRef} style={{width:'100%',height: this.state.containerHeight}} ></div> */}
        <a href="/" id="hidden-link" className="sr-only"> </a>
      </div>
    )
}}
  
  export default Content;
