import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Tooltip } from 'reactstrap';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import FilterOffIcon from 'mdi-react/FilterOffIcon';
import { Link } from 'react-router-dom';

const PageTooltip = (props) => {
  const [modal, setModal] = useState(false);
  //const [refreshTooltipOpen, setRefreshTooltipOpen] = useState(false);
  const [infoTooltipOpen, setInfoTooltipOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  
  const {refreshTime: [refreshTime, setRefreshTime]} = {
    refreshTime: useState(0),
    ...(props.state || {}),
  };

  const toggle = () => setModal(!modal);
  //const toggleRefreshTooltip = () => setRefreshTooltipOpen(!refreshTooltipOpen);
  const toggleInfoTooltip = () => setInfoTooltipOpen(!infoTooltipOpen);

  const clearQueryParameters = () => {
    if(location.search !== '') {
      history.push(location.pathname);
    } 
  }

  const sendRefreshBeacon = () => {
    if(typeof window._satellite !== "undefined") 
      window._satellite.track("site_tool.interaction",{"toolName":"Dashboard Refreshed"});
  }

  return (
    <div className='page-info-section'>
      <button id='page-info--refresh' className='btn-icon btn-refresh' data-refreshtime={refreshTime} onClick={() => {
        clearQueryParameters();
        setRefreshTime(Date.now());
        sendRefreshBeacon();
      }} >Clear Filters <FilterOffIcon aria-hidden='true' /><span className='sr-only'>Clear dashboard filters</span></button>
      {/* <Tooltip className="hover-tooltip" innerClassName="hover-tooltip__inner" arrowClassName="hover-tooltip__arrow" key="refresh-1" placement="top" fade={false} flip={false} delay={{ show: 0, hide: 100 }} isOpen={refreshTooltipOpen} target="page-info--refresh" toggle={toggleRefreshTooltip}>
        Clear dashboard filters
      </Tooltip> */}
      <button id='page-info--helper' className='btn-icon btn-info-helper' onClick={toggle} ><InformationOutlineIcon aria-hidden='true' /><span className='sr-only'>Page interaction tips</span></button>
      <Tooltip className="hover-tooltip" innerClassName="hover-tooltip__inner" arrowClassName="hover-tooltip__arrow" key="info-1" placement="top" fade={false} flip={false} delay={{ show: 0, hide: 100 }} isOpen={infoTooltipOpen} target="page-info--helper" toggle={toggleInfoTooltip}>
        Show page info
      </Tooltip>
      <Modal 
        isOpen={modal} 
        target='page-info--helper' 
        scrollable={true}
        toggle={toggle}>
        <ModalHeader toggle={toggle}>Page Information</ModalHeader>
        <ModalBody>
          <p>Your program&rsquo;s data is represented on this page by <em>Cards</em>. Cards appear as boxes and may contain visualizations or tables displaying the underlying data. Cards have a consistent structure with a few key elements:</p>
          <ul>
            <li key="info-1"><strong>Title</strong>: The title describes the card. Hovering over the title shows more information about how to use the metric(s) shown on the card.</li>
            <li key="info-2"><strong>Export button</strong>: This button is located in the top-right of the card when hovering over the card. Clicking the button downloads a CSV file of the raw data used in the card. Using the export button in the expanded view allows additional export options: Print and PowerPoint, for a snapshot of what is displayed on the card.</li>
            <li key="info-3"><strong>Expand button</strong>: This button is located in the top-right of the card when hovering over the card. Clicking the button expands the card to show more information. You can also change to a table view in this expanded view.</li>
            <li key="info-4"><strong>Filter icon</strong>: When a filter has been applied on a card, this icon will display in the lower-right of the card. Hovering over the icon will show what filters have been applied to the card.</li>
          </ul>
          <p><strong>Filters</strong><br /> Filter cards are used to filter the data represented on one or more other cards on the page. Filter examples include date, donor names and email/landing page types. Filters can be cleared by unchecking the box that is selected or hovering over the filter card and clicking the funnel icon in the top-right of the card. </p>
          <p>Selecting data points on some cards can also be used to filter other cards on the page. For example, clicking on a donor&rsquo;s email address in the "Top Leads" card will filter the "Lead Activity" to show only that donor&rsquo;s activity.</p>
          <p><strong>Drilling into the data</strong><br /> Some cards offer "drill paths" that allow you to dig deeper into the data. For example, clicking on a month in the "Form Submissions by Month" card will drill down to a table of the information submitted on each of the forms.</p>
          <p><strong>Questions?</strong><br /> Head over to the <Link to="/app/support/request">support page</Link> to submit your question or review other helpful resources.</p>
        </ModalBody>
      </Modal>
    </div>
  )
};

export default PageTooltip;