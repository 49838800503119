import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

export const IdleModal = ({showModal, handleClose, warningTimeout}) => {

  const initialSeconds = warningTimeout;
  const [seconds, setSeconds ] =  useState(initialSeconds);
  let [isLoggedOut, setLoggedOut] = useState(false);

  const logout = () => setLoggedOut(!isLoggedOut);
  const stillHere = () => {
    setSeconds(initialSeconds);
    handleClose();
  }

  useEffect(()=>{
    if(showModal) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
            setSeconds(seconds - 1);
        } else {
          clearInterval(myInterval)
          setSeconds(initialSeconds);
          logout();
        }
    }, 1000)

    return ()=> {
        clearInterval(myInterval);
      };
    }
  });
  
  return (isLoggedOut ? <Redirect to="/logout" /> :
    <Modal 
      isOpen={showModal} 
      scrollable={false}>
      <ModalHeader >Are you still here?</ModalHeader>
      <ModalBody>
        <p>You will be automatically logged out in {seconds} seconds.</p>
        <p>If you want to stay logged in, confirm now.</p>
        <p className="text-right">
          <Button outline color='secondary' onClick={logout}>Log out</Button>
          <Button color='primary' onClick={stillHere}>I&rsquo;m still here!</Button>
        </p>
      </ModalBody>
    </Modal>
  )

  
};