import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PageTooltip from '../../../shared/PageTooltip';
import LeadsContent from './components/LeadsContentOld';

const Page = () => {
  const [refreshTime, setRefreshTime] = useState(0);

  return (
    <div className="container__wrap">
    <Container className="dashboard">
      <PageTooltip state={{ refreshTime: [refreshTime, setRefreshTime] }}/>
      <Row className="row__dashboard-intro">
        <Col md={12} className="dashboard-intro">
          <h3 className="page-title dashboard-title">Leads</h3>
          <p className="dashboard-subtitle">Review lead activity to drive conversations with your donors. Select a lead from the table to view details in the Lead Activity cards on the right.</p>
        </Col>
      </Row>
      <Row>
        <LeadsContent key={refreshTime}/>
      </Row>
    </Container>
    </div>
  );
};

export default Page;
