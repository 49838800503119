import React, { PureComponent } from 'react';
import { store } from '../../../App/store';
import { Col, Row, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import LoadingIcon from '../../../../shared/LoadingIcon';
import { fetchEmbed } from '../../../../utils/helpers';
import Downshift from 'downshift';
import ExportVariantIcon from 'mdi-react/ExportVariantIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import ArrowLeftCircleIcon from 'mdi-react/ArrowLeftCircleIcon';
import AlertDecagramIcon from 'mdi-react/AlertDecagramIcon';
import Cookies from 'universal-cookie';
//eslint-disable-next-line
import exportWorker from "worker-loader!../../../../leadsWorker.js";
const worker = new exportWorker();
const cookies = new Cookies();
const embedItem = 'customLeads';

const ArrowIcon = ({isOpen}) => {
  return (
      <svg
          viewBox="0 0 20 20"
          preserveAspectRatio="none"
          width={16}
          fill="transparent"
          stroke="#979797"
          strokeWidth="1.1px"
          transform={isOpen ? 'rotate(180)' : undefined}
      >
      <path d="M1,6 L10,15 L19,6" />
      </svg>
  )
}

const XIcon = () => {
  return (
      <svg
          viewBox="0 0 20 20"
          preserveAspectRatio="none"
          width={12}
          fill="transparent"
          stroke="#979797"
          strokeWidth="1.1px"
      >
      <path d="M1,1 L19,19" />
      <path d="M19,1 L1,19" />
      </svg>
  )
}

const popoverContent = {
  timeframeFilter: "Filter to the last 90 days or 365 days of activity.",
  searchByNameFilter: "Search by Name or Email to see engagement details on the right.",
  leadGroupFilter: "The lead score group by engagement level.",
  activityFilter: "The activity included in the lead scoring process.",
  sourcesFilter: "The source of the lead’s visit to the website.",
  topLeadsSummary: "",
  topLeadsDetails: "Details about the lead’s activity across web and email.",
  whatsNewIcon: "What’s New",
  exportIcon: "Export Leads"
};

class Content extends PureComponent {
  static contextType = store;

  constructor(props){
    super(props);
    this.state = {
      isLocal: false,
      loading: false,
      loaded: false,
      iframeHeight: '100%',
      containerHeight: 'calc(100vh - 150px)',
      appliedFilters: [],
      leadGroupTimeframe: 90,
      initialTimeframeDate: new Date(new Date().setDate((new Date((new Date()).setHours(0,0,0))).getDate() - 90)),
      currentLeads: [],
      leadsNameEmails: '',
      drillNameEmail: '',
      showSummaryTable: true,
      summaryPage: 1,
      pages: [1],
      summaryStart: 0,
      summaryEnd: 1000,
      selectedGroups: [],
      currentSelectedGroups: [],
      groupsOpen: false,
      selectedActivities: [],
      currentSelectedActivities: [],
      activitiesOpen: false,
      activitiesClass: 'invisible',
      selectedSources: [],
      currentSelectedSources: [],
      sourcesOpen: false,
      visitSourcesClass: 'invisible',
      tooltipContent: '',
      showCalendar: true,
      startDate: '',
      endDate: '',
      dateRange: null,
      csvExportLoading: false,
      showExportMenu: false,
      showEditMenu: false,
      showWhatsNew: false,
      showWhatsNewAlert: false,
      showFilterBackdrop: false
    }
    this.containerRef = React.createRef();
  }

  copyLink = (url) => {
		var input = document.createElement('input');
		input.type = 'text';
		input.className = 'hidden-input';
		input.value = url;
		document.body.appendChild(input);
		input.select()
		document.execCommand('copy');
		document.body.removeChild(input);

		this.setState({inviteLinkCopied: url})
		setTimeout(() => {
			this.setState({inviteLinkCopied: ''})
		}, 1000);
	}

  setSummaryPage = (page) => {
    const globalState = this.context;
    const { leadsSummaryData } = globalState.state;
 		let endRange = page * 1000;
    let startRange = endRange - 1000;
    let selectedLeads = leadsSummaryData.slice(startRange,endRange).filter(row => row['LeadScoreGroup'+this.state.leadGroupTimeframe+'Days'] !== 'No Score')

    if(this.state.appliedFilters.indexOf("groups") > -1) {
      selectedLeads = selectedLeads.filter(row => this.state.currentSelectedGroups.indexOf(row["LeadScoreGroup"+this.state.leadGroupTimeframe+"Days"]) > -1);
    }

    if(this.state.appliedFilters.indexOf("activities") > -1) {
      selectedLeads = selectedLeads.filter(row => this.state.currentSelectedActivities.some(activity => row.Activities.indexOf(activity) > -1));
    }

    if(this.state.appliedFilters.indexOf("sources") > -1) {
      selectedLeads = selectedLeads.filter(row => this.state.currentSelectedSources.some(source => row.VisitSources.indexOf(source) > -1));
    }

		this.setState({
      currentLeads: selectedLeads,
      summaryPage: page,
      summaryStart: startRange,
      summaryEnd: endRange
    })
	}

  drillToNameEmail = (nameEmail) => {
    if (nameEmail === '') {
      this.setState({
        showSummaryTable: true,
        showExportMenu: false,
        showEditMenu: false,
        drillNameEmail: '',
      })
    } else {
      this.setState({
        showSummaryTable: false,
        showExportMenu: false,
        showEditMenu: false,
        drillNameEmail: nameEmail
      })
    }
	}

  compareValues = (key, order = 'asc', isLeadGroup) => {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      
      // set correct order for lead groups
      let leadGroups = ["No Score","Cool","Warm","Hot"];
      if (leadGroups.indexOf(a[key]) > -1) {
        if(
          (varA === "NO SCORE") ||
          (varA === "COOL" && (varB === "WARM" || varB === "HOT")) ||
          (varA === "WARM" && (varB === "HOT"))
        ) {
          comparison = -1
        } else if (
            (varA === "HOT") ||
            (varA === "WARM" && (varB === "COOL" || varB === "NO SCORE")) ||
            (varA === "COOL" && (varB === "NO SCORE"))
        ) {
          comparison = 1
        }
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  sortTable = (key, order = 'asc') => {
    let { currentLeads, sortKey, sortOrder } = this.state;
    if((key === 'GiftIntentionSimple' || key === 'LeadScoreGroup'+this.state.leadGroupTimeframe+'Days') && typeof sortOrder === 'undefined') order = 'desc';
    order = key === sortKey && sortOrder === 'asc' ? 'desc' : key === sortKey && sortOrder === 'desc' ? 'asc' : order;

    let sortedTableData = key !== 'DonorID' && key !== 'GiftIntentionSimple' ? currentLeads.sort(this.compareValues(key, order)) : [...currentLeads.filter(row => row[key] !== '').sort(this.compareValues(key, order)),...currentLeads.filter(row => row[key] === '')]

    this.setState({
      currentLeads: sortedTableData, 
      sortKey: key, 
      sortOrder: order
    })
  }

  getClassNamesFor = (name) => {
    const { sortKey, sortOrder } = this.state;
    if (!sortKey || sortKey === '') {
        return '';
    }
    return sortKey === name ? sortOrder : '';
  }

  toggleExportMenu = () => {
    this.setState({
      showExportMenu: !this.state.showExportMenu,
      showEditMenu: false
    })
  }

  toggleEditMenu = () => {
    this.setState({
      showEditMenu: !this.state.showEditMenu,
      showExportMenu: false
    })
  }

  toggleWhatsNew = () => {
    //let showWhatsNewAlert = false;
    // if(typeof cookies.get('leadsWhatsNewAlert') === 'undefined') {
    //   cookies.set('leadsWhatsNewAlert', true, {path: '/', secure: true, sameSite: 'strict', maxAge: 365 * 24 * 60 * 60});
    // }
    //showWhatsNewAlert: showWhatsNewAlert
    this.setState({
      showWhatsNew: !this.state.showWhatsNew,
      
    })
  }

  exportLeads = (exportType, data) => {
    const globalState = this.context;
    const { isAdmin, leadsData, leadsSummaryData } = globalState.state;
    let { drillNameEmail } = this.state;
    try{
      this.setState({csvExportLoading: true})
      if(exportType === 'leads-summary-table') {
        worker.postMessage({eventType: 'exportLeads', exportType: exportType, leadNameEmail: drillNameEmail, leadGroupTimeframe: this.state.leadGroupTimeframe, leadsData: [], leadsSummaryData: leadsSummaryData, leadData: []});
      }
      if(exportType === 'leads-summary-details') {
        worker.postMessage({eventType: 'exportLeads', exportType: exportType, leadNameEmail: drillNameEmail, leadGroupTimeframe: this.state.leadGroupTimeframe, leadsData: leadsData, leadsSummaryData: [], leadData: []});
      }
      if(exportType === 'lead-details') {
        worker.postMessage({eventType: 'exportLeads', exportType: exportType, leadNameEmail: drillNameEmail, leadGroupTimeframe: this.state.leadGroupTimeframe, leadsData: [], leadsSummaryData: [], leadData: data});
      }
  
      worker.onmessage = (event) => {
        if(isAdmin) {
          console.log("Received response:");
          console.log(event.data);
        }

        if (event.data.state === 'download_csv') {
          let csvFile = event.data.blob;
          let csvFilename = event.data.csvFilename;
          const link = document.createElement('a');
          link.href = URL.createObjectURL(csvFile);
          link.download = csvFilename;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          this.setState({csvExportLoading: false})
        }
      }

      this.setState({csvExportLoading: false})
    } catch(e) {
      if(isAdmin) console.log(e)
      this.setState({csvExportLoading: false})
    }
  }

  componentDidMount = () => {
    const globalState = this.context;
    const { userGroup, leadsSummaryData, leadActivities, visitSources } = globalState.state;
    let { pages } = this.state;
    const position = this.containerRef.current.getBoundingClientRect();
    const topPosition = position.top;
    const isAdmin = userGroup === 'Admin';
    const isLocal = window.location.hostname.indexOf('localhost') > -1;

    // let showWhatsNewAlert = false;
    // if(typeof cookies.get('leadsWhatsNewAlert') === 'undefined') {
    //   showWhatsNewAlert = true;
    // }
    // showWhatsNewAlert: showWhatsNewAlert
    this.setState({
      isLocal: isLocal,
      loading: true,
      containerHeight: `calc(100vh - ${topPosition}px)`
    });

    let pFilters = '';
    this.fetchEmbed = fetchEmbed.bind(this);

    const trackPage = () => {
      // not a real embed fetch, just needed to show Leads page view
      this.fetchEmbed(embedItem, pFilters, isAdmin);
    }

    const checkData = () => {
      if(isAdmin) {
        console.log('mount (leadsExist): ' + this.props.leadsExist)
        console.log('leadsSummaryData.length: ' + leadsSummaryData.length)
      }
      if(!this.props.leadsExist) {
        setTimeout(checkData, 100); /* this checks the flag every 100 milliseconds*/
      } else {
        
        let initialLeads = leadsSummaryData.filter(row => row['LeadScoreGroup90Days'] !== 'No Score')
        if(initialLeads.length > 1000) {
          pages = [];
          var totalPages = Math.ceil(initialLeads.length / 1000);
          for (var i = 1; i <= totalPages; i++) {
            pages.push(i);
          }
        }

        if(isAdmin) {
          console.log('current leads: ' + JSON.stringify(initialLeads.length))
          console.log('leadActivities: ' + JSON.stringify(leadActivities))
          console.log('visitSources: ' + JSON.stringify(visitSources))
        }

        this.setState({
          loading: false, 
          loaded: true,
          pages: pages,
          currentLeads: initialLeads,
        }); 
      }
    }
    checkData();
    trackPage();
  }

  renderFilters() {
    const globalState = this.context;
    const { leadsSummaryData, leadsNameEmails, leadActivities, visitSources } = globalState.state;
    let { appliedFilters, drillNameEmail, selectedGroups, currentSelectedGroups, groupsOpen, selectedActivities, currentSelectedActivities, activitiesOpen, activitiesClass, selectedSources, currentSelectedSources, sourcesOpen, visitSourcesClass, showFilterBackdrop } = this.state;

    const applyFilters = (filters) => {
      console.log('filters: ' + filters)
      let selectedLeads = leadsSummaryData.filter(row => row["LeadScoreGroup"+this.state.leadGroupTimeframe+"Days"] !== 'No Score');
      if (filters.includes('dates')) {
        selectedLeads = selectedLeads.filter(row => new Date(row['LastActivityDate']) >= this.state.startDate && new Date(row['LastActivityDate']) <= this.state.endDate);
      }
      if(filters.includes("groups")) {
        selectedLeads = selectedLeads.filter(row => this.state.currentSelectedGroups.indexOf(row["LeadScoreGroup"+this.state.leadGroupTimeframe+"Days"]) > -1);
      }
      if(filters.includes("activities")) {
        selectedLeads = selectedLeads.filter(row => this.state.currentSelectedActivities.some(activity => row.Activities.indexOf(activity) > -1));
      }
      if(filters.includes("sources")) {
        selectedLeads = selectedLeads.filter(row => this.state.currentSelectedSources.some(source => row.VisitSources.indexOf(source) > -1));
      }

      let newPages = [];
      if(selectedLeads.length > 1000) {
        var totalPages = Math.ceil(selectedLeads.length / 1000);
        for (var i = 1; i <= totalPages; i++) {
          newPages.push(i);
        }
      }

      let sortedSelectedLeads = [...selectedLeads.filter(row => row["LeadScoreGroup"+this.state.leadGroupTimeframe+"Days"] === "Hot").sort(this.compareValues('LastActivityDate','desc')),...selectedLeads.filter(row => row["LeadScoreGroup"+this.state.leadGroupTimeframe+"Days"] === "Warm").sort(this.compareValues('LastActivityDate','desc')),...selectedLeads.filter(row => row["LeadScoreGroup"+this.state.leadGroupTimeframe+"Days"] === "Cool").sort(this.compareValues('LastActivityDate','desc'))]

      this.setState({
        currentLeads: sortedSelectedLeads,
        pages: newPages,
        summaryStart: 0,
        summaryEnd: 1000
      })
    };

    const clearName = () => {
      this.setState({
        drillNameEmail: '',
        showSummaryTable: true
      })
    }

    const closeFilters = () => {
      this.setState({
        groupsOpen: false,
        activitiesOpen: false,
        sourcesOpen: false,
        showFilterBackdrop: false
      })
    }

    const UserSearchCombobox = () => {
      return (
        <Downshift
          onChange={selection => {
            if(selection) {
              console.log(`You selected ${selection.value}`)
              this.setState({
                drillNameEmail: selection.value,
                showSummaryTable: false
              })
            } else {
              console.log(`You cleared selections`)
            }
          }}
          itemToString={item => (item ? item.value : '')}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            getLabelProps,
            getToggleButtonProps,
            inputValue,
            highlightedIndex,
            selectedItem,
            isOpen,
          }) => (
            <div id="lead-name-selector" className="combobox lead-name-selector">
              <label className="tooltip-title tooltip-label" {...getLabelProps()}>Lead Search <span className="tooltip-text">{popoverContent.searchByNameFilter} <span className="tooltip-arrow" /></span></label>
              <div className="combobox__input-wrapper">
                <input {...getInputProps()} placeholder={drillNameEmail !== '' ? drillNameEmail : "Enter Name or Email"}/>
                {drillNameEmail !== '' ? (
                    <button onClick={() => clearName()} aria-label="clear selection">
                        <XIcon />
                    </button>
                    ) : (
                    <button aria-label="toggle search" aria-haspopup="true" data-toggle="true" {...getToggleButtonProps()}>
                        <ArrowIcon isOpen={isOpen} />
                    </button>
                    
                )}
            </div>
              <ul className="combobox__options" {...getMenuProps()}>
                {isOpen &&
                  leadsNameEmails
                    .filter(
                      item => !inputValue || item.value.toLowerCase().includes(inputValue.toLowerCase()),
                    )
                    .map((item, index) => (
                      <li
                        {...getItemProps({
                          key: `${item.value}${index}`,
                          item,
                          index,
                          style: {
                            backgroundColor:
                                highlightedIndex === index ? '#e2e4e7' : '',
                            fontWeight: selectedItem === item ? 'bold' : 'normal',
                            },
                        })}
                      >
                        {item.value}
                      </li>
                    ))}
              </ul>
            </div>
          )}
        </Downshift>
      )
    }

    const TimeframeSelect = () => { 
      let { leadGroupTimeframe } = this.state;

      const selectTimeframe = (timeframe) => {
        let newTimeframeDate = new Date(new Date(new Date().setDate(new Date().getDate() - timeframe)).setHours(0,0,0));
        let selectedFilters = [...appliedFilters]
        this.setState({
          leadGroupTimeframe: timeframe,
          initialTimeframeDate: newTimeframeDate,
          appliedFilters: selectedFilters
        }, () => applyFilters(selectedFilters))
      }

      return (
        <div className="radio-selector lead-group-timeframe-selector">
          <fieldset>
            <legend className="tooltip-title tooltip-legend">Lead Group Timeframe <span className="tooltip-text">{popoverContent.timeframeFilter} <span className="tooltip-arrow" /></span></legend>
            <div className="radio-selector-item">
              <label><input type="radio" id="radio-ninety" name="timeframe" value="90" checked={leadGroupTimeframe === 90} onChange={()=> selectTimeframe(90)}/> <span>90 Days</span></label>
            </div>
            <div className="radio-selector-item">
              <label><input type="radio" id="radio-year" name="timeframe" value="365" checked={leadGroupTimeframe === 365} onChange={()=> selectTimeframe(365)}/> <span>365 Days</span></label>
            </div>
          </fieldset>
        </div>
      )
    }

    const LeadGroupSelect = () => { 
      let buttonText = currentSelectedGroups.length > 0 ? `${currentSelectedGroups.join(', ')}` : 'Select';

      const items = [
        'Hot',
        'Warm',
        'Cool'
      ];

      const toggleMenu = () => {
        if (groupsOpen) {
          this.setState({
            groupsOpen: false,
            showFilterBackdrop: false
          })
        } else {
          this.setState({
            groupsOpen: true,
            activitiesOpen: false,
            sourcesOpen: false,
            selectedGroups: currentSelectedGroups,
            showFilterBackdrop: true
          })
        }
      }

      const checkItem = (item) => {
        if(selectedGroups.includes(item)){
          var items = [...selectedGroups].filter(el => el !== item);
          this.setState({
            selectedGroups: items
          })
        } else {
          var newSelectedGroups = [...selectedGroups,item]
          this.setState({
            selectedGroups: newSelectedGroups
          })
        }
      }

      const applyGroups = (selectedItems) => {
        // selectedItems
        console.log('selected groups: ' + selectedItems)
        let sortedItems = [];
        //let selectedLeads = [];
        let selectedFilters = this.state.appliedFilters.indexOf('groups') === -1 ? [...this.state.appliedFilters, 'groups'] : [...this.state.appliedFilters];
        if (selectedItems.length > 0) {
          sortedItems = selectedItems.slice().sort((a, b) => items.indexOf(a) - items.indexOf(b));
        } else {
          selectedFilters = appliedFilters.filter(filter => filter !== 'groups')
        }
        
        this.setState({
          //currentLeads: selectedLeads,
          currentSelectedGroups: sortedItems,
          groupsOpen: false,
          appliedFilters: selectedFilters,
          showFilterBackdrop: false
        }, () => applyFilters(selectedFilters))
      }

      const cancelGroups = () => {
        this.setState({
          selectedGroups: currentSelectedGroups,
          groupsOpen: false,
          showFilterBackdrop: false
        })
      }

      const clearGroups = () => {
        //let selectedLeads = leadsSummaryData.slice(summaryStart,summaryEnd);
        let selectedFilters = appliedFilters.filter(filter => filter !== 'groups')
        this.setState({
          selectedGroups: [],
          currentSelectedGroups: [],
          //currentLeads: selectedLeads,
          showSummaryTable: true,
          groupsOpen: false,
          appliedFilters: selectedFilters,
          showFilterBackdrop: false
        }, () => applyFilters(selectedFilters))
      }

      return (
        <div className="checkbox-selector lead-group-selector">
          <fieldset>
            <legend className="tooltip-title tooltip-legend">Lead Group <span className="tooltip-text">{popoverContent.leadGroupFilter} <span className="tooltip-arrow" /></span></legend>
            <div className="checkbox-selector-toggle-wrapper">
              <button type="button" className="checkbox-selector-toggle" onClick={()=> toggleMenu()} aria-label="toggle menu">
                {buttonText}
              </button>
              {currentSelectedGroups.length > 0 ? (
                  <span type="button" className="checkbox-selector-toggle-icon" onClick={() => clearGroups()} aria-label="clear selection">
                      <XIcon />
                  </span>
                  ) : (
                  <span className="checkbox-selector-toggle-icon" onClick={() => toggleMenu()} aria-label="open menu">
                      <ArrowIcon />
                  </span>
                  
              )}
            </div>
            {
              groupsOpen &&
              <div className="select-dropdown checkbox-dropdown">
                <ul>
                  {groupsOpen &&
                    items.map((item, index) => (
                      <li
                        key={`${item}${index}`}
                      >
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedGroups.includes(item)}
                            value={item}
                            onChange={() => checkItem(item)}
                          />
                          {item}
                        </label>
                      </li>
                    ))}
                </ul>
                <div className="dropdown-submit-btn-wrapper">
                  <button className="dropdown-submit-btn cancel-btn" onClick={() => cancelGroups()}>Cancel</button>
                  <button className="dropdown-submit-btn apply-btn" onClick={() => applyGroups(selectedGroups)}>Apply</button>
                </div>
              </div>
            }
          </fieldset>
        </div>
      )
    }

    const ActivitySelect = () => { 
      let buttonText = currentSelectedActivities.length > 0 ? `${currentSelectedActivities.join(', ')}` : 'Select';

      const items = leadActivities.map(el => el.Activity)

      const toggleMenu = () => {
        if (activitiesOpen) {
          this.setState({
            activitiesOpen: false,
            activitiesClass: 'invisible',
            showFilterBackdrop: false
          })
        } else {
          this.setState({
            activitiesOpen: true,
            groupsOpen: false,
            sourcesOpen: false,
            selectedActivities: currentSelectedActivities,
            showFilterBackdrop: true
          })
          setTimeout(() => {
            //var bounding = document.querySelector('.activity-selector .select-dropdown').getBoundingClientRect();
            // (bounding.bottom + 30 > (window.innerHeight || document.documentElement.clientHeight))
            if (window.innerWidth > 768) {
              this.setState({
                activitiesClass: 'visible sidemenu'
              })
            } else {
              this.setState({
                activitiesClass: 'visible'
              })
            }
          }, 100);
        }
        
      }

      const checkItem = (item) => {
        if(selectedActivities.includes(item)){
          var items = [...selectedActivities].filter(el => el !== item);
          this.setState({
            selectedActivities: items
          })
        } else {
          var newSelectedActivities = [...selectedActivities,item]
          this.setState({
            selectedActivities: newSelectedActivities
          })
        }
      }

      const applyActivities = (selectedItems) => {
        // selectedItems
        console.log('selectedItems: ' + selectedItems)
        let sortedItems = [];
        //let selectedLeads = [];
        let selectedFilters = [...appliedFilters,'activities'];
        if (selectedItems.length > 0) {
          sortedItems = selectedItems.slice().sort((a, b) => items.indexOf(a) - items.indexOf(b));
          // selectedLeads = leadsSummaryData.filter(el => sortedItems.every(item => el.Activities.indexOf(item) > -1));
          // if(currentSelectedGroups.length > 0) {
          //   selectedLeads = selectedLeads.filter(el => currentSelectedGroups.indexOf(el.LeadScoreGroup365Days) > -1);
          // }
        } else {
          //selectedLeads = leadsSummaryData;
          selectedFilters = appliedFilters.filter(filter => filter !== 'activities')
        }
        
        this.setState({
          //currentLeads: selectedLeads,
          currentSelectedActivities: sortedItems,
          activitiesOpen: false,
          activitiesClass: 'invisible',
          appliedFilters: selectedFilters,
          showFilterBackdrop: false
        }, () => applyFilters(selectedFilters))
      }

      const cancelActivities = () => {
        this.setState({
          selectedActivities: currentSelectedActivities,
          activitiesOpen: false,
          activitiesClass: 'invisible',
          showFilterBackdrop: false
        })
      }

      const clearActivities = () => {
        let selectedFilters = appliedFilters.filter(filter => filter !== 'activities')
        this.setState({
          selectedActivities: [],
          currentSelectedActivities: [],
          //currentLeads: selectedLeads,
          showSummaryTable: true,
          activitiesOpen: false,
          activitiesClass: 'invisible',
          appliedFilters: selectedFilters,
          showFilterBackdrop: false
        }, () => applyFilters(selectedFilters))
      }

      return (
        <div className="checkbox-selector activity-selector">
          <fieldset>
            <legend className="tooltip-title tooltip-legend">Activity <span className="tooltip-text">{popoverContent.activityFilter} <span className="tooltip-arrow" /></span></legend>
            <div className="checkbox-selector-toggle-wrapper">
              <button type="button" className="checkbox-selector-toggle" onClick={()=> toggleMenu()}>
                {buttonText}
              </button>
              {currentSelectedActivities.length > 0 ? (
                  <span type="button" className="checkbox-selector-toggle-icon" onClick={() => clearActivities()} aria-label="clear selection">
                      <XIcon />
                  </span>
                  ) : (
                  <span className="checkbox-selector-toggle-icon" onClick={() => toggleMenu()} aria-label="open menu">
                      <ArrowIcon />
                  </span>
                  
              )}
            </div>
            {
              activitiesOpen &&
              <div className={activitiesClass + " select-dropdown checkbox-dropdown"}>
                <ul>
                  {activitiesOpen &&
                    items.map((item, index) => (
                      <li
                        key={`${item}${index}`}
                      >
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedActivities.includes(item)}
                            value={item}
                            onChange={() => checkItem(item)}
                          />
                          {item}
                        </label>
                      </li>
                    ))}
                </ul>
                <div className="dropdown-submit-btn-wrapper">
                  <button className="dropdown-submit-btn cancel-btn" onClick={() => cancelActivities()}>Cancel</button>
                  <button className="dropdown-submit-btn apply-btn" onClick={() => applyActivities(selectedActivities)}>Apply</button>
                </div>
              </div>
            }
          </fieldset>
        </div>
      )
    }

    const VisitSourceSelect = () => { 
      let buttonText = currentSelectedSources.length > 0 ? `${currentSelectedSources.join(', ')}` : 'Select';

      const items = visitSources.map(el => el.VisitSource)

      const toggleMenu = () => {
        if (sourcesOpen) {
          this.setState({
            sourcesOpen: false,
            showFilterBackdrop: false,
            visitSourcesClass: 'invisible'
          })
        } else {
          this.setState({
            sourcesOpen: true,
            activitiesOpen: false,
            groupsOpen: false,
            showFilterBackdrop: true,
            selectedSources: currentSelectedSources
          })
          setTimeout(() => {
            //var bounding = document.querySelector('.visit-source-selector .select-dropdown').getBoundingClientRect();
            // (bounding.bottom + 30 > (window.innerHeight || document.documentElement.clientHeight))
            if (window.innerWidth > 768) {
              this.setState({
                visitSourcesClass: 'visible sidemenu'
              })
            } else {
              this.setState({
                visitSourcesClass: 'visible'
              })
            }
          }, 100);
          
        }
        
      }

      const checkItem = (item) => {
        if(selectedSources.includes(item)){
          var items = [...selectedSources].filter(el => el !== item);
          this.setState({
            selectedSources: items
          })
        } else {
          var newSelectedSources = [...selectedSources,item]
          this.setState({
            selectedSources: newSelectedSources
          })
        }
      }

      const applySources = (selectedItems) => {
        // selectedItems
        console.log('selectedItems: ' + selectedItems)
        let sortedItems = [];
        //let selectedLeads = [];
        let selectedFilters = [...appliedFilters,'sources'];
        if (selectedItems.length > 0) {
          sortedItems = selectedItems.slice().sort((a, b) => items.indexOf(a) - items.indexOf(b));
          // selectedLeads = leadsSummaryData.filter(el => sortedItems.every(item => el.Activities.indexOf(item) > -1));
          // if(currentSelectedGroups.length > 0) {
          //   selectedLeads = selectedLeads.filter(el => currentSelectedGroups.indexOf(el.LeadScoreGroup365Days) > -1);
          // }
        } else {
          //selectedLeads = leadsSummaryData;
          selectedFilters = appliedFilters.filter(filter => filter !== 'sources')
        }
        
        this.setState({
          //currentLeads: selectedLeads,
          currentSelectedSources: sortedItems,
          sourcesOpen: false,
          visitSourcesClass: 'invisible',
          appliedFilters: selectedFilters,
          showFilterBackdrop: false
        }, () => applyFilters(selectedFilters))
      }

      const cancelSources = () => {
        this.setState({
          selectedSources: currentSelectedSources,
          sourcesOpen: false,
          visitSourcesClass: 'invisible',
          showFilterBackdrop: false
        })
      }

      const clearSources = () => {
        let selectedFilters = appliedFilters.filter(filter => filter !== 'sources')
        this.setState({
          selectedSources: [],
          currentSelectedSources: [],
          //currentLeads: selectedLeads,
          showSummaryTable: true,
          sourcesOpen: false,
          visitSourcesClass: 'invisible',
          appliedFilters: selectedFilters,
          showFilterBackdrop: false
        }, () => applyFilters(selectedFilters))
      }

      return (
        <div className="checkbox-selector visit-source-selector">
          <fieldset>
            <legend className="tooltip-title tooltip-legend">Visit Source <span className="tooltip-text">{popoverContent.sourcesFilter} <span className="tooltip-arrow" /></span></legend>
            <div className="checkbox-selector-toggle-wrapper">
              <button type="button" className="checkbox-selector-toggle" onClick={()=> toggleMenu()}>
                {buttonText}
              </button>
              {currentSelectedSources.length > 0 ? (
                  <span type="button" className="checkbox-selector-toggle-icon" onClick={() => clearSources()} aria-label="clear selection">
                      <XIcon />
                  </span>
                  ) : (
                  <span className="checkbox-selector-toggle-icon" onClick={() => toggleMenu()} aria-label="open menu">
                      <ArrowIcon />
                  </span>
                  
              )}
            </div>
            {
              sourcesOpen &&
              <div className={visitSourcesClass + " select-dropdown checkbox-dropdown"}>
                <ul>
                  {sourcesOpen &&
                    items.map((item, index) => (
                      <li
                        key={`${item}${index}`}
                      >
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedSources.includes(item)}
                            value={item}
                            onChange={() => checkItem(item)}
                          />
                          {item}
                        </label>
                      </li>
                    ))}
                </ul>
                <div className="dropdown-submit-btn-wrapper">
                  <button className="dropdown-submit-btn cancel-btn" onClick={() => cancelSources()}>Cancel</button>
                  <button className="dropdown-submit-btn apply-btn" onClick={() => applySources(selectedSources)}>Apply</button>
                </div>
              </div>
            }
          </fieldset>
        </div>
      )
    }

    return (
      <div className="filter-cards">
        <Card className="top-margin auto-height">
          <CardBody className="card-sm">
            <TimeframeSelect />
          </CardBody>
        </Card>
        <Card className="top-margin auto-height">
          <CardBody className="card-sm">
            <UserSearchCombobox />
          </CardBody>
        </Card>
        <Card className="top-margin auto-height">
          <CardBody className="card-sm">
            <LeadGroupSelect />
          </CardBody>
        </Card>
        <Card className="top-margin auto-height">
          <CardBody className="card-sm">
            <ActivitySelect />
          </CardBody>
        </Card>
        <Card className="top-margin auto-height">
          <CardBody className="card-sm">
            <VisitSourceSelect />
          </CardBody>
        </Card>
        {showFilterBackdrop &&
          <div className="modal-filter-backdrop fade show" onClick={()=> closeFilters()}></div>
        }
      </div>
    )
  }

  render() {
    const globalState = this.context;
    const { leadsData, leadsSummaryData } = globalState.state;
    // let { leadsExist } = this.props;
		let { drillNameEmail, showSummaryTable, leadGroupTimeframe, pages, currentLeads, showWhatsNew, showWhatsNewAlert } = this.state;

    // array for showing only first date instance in the lead details table
    let detailsDateArr = [];

    let leadData = [];
    if (drillNameEmail !== '') {
      leadData = leadsData.filter(row => row.NameEmail === drillNameEmail);
    }

		return (
      <Col md={12}>
        <Card>
          <div className={"App wrapper loaded-" + this.state.loaded} ref={this.containerRef}>
            {!this.props.leadsExist && this.state.loaded ? (
                <LoadingIcon />
              ) : (
                <div className="app-container">
                  <Row className="non-iframe-container">
                    <Col md={3} className="flex-container flex-col">
                      {this.renderFilters()}
                    </Col>
                    <Col md={9} className="flex-container flex-row">
                      {showSummaryTable ? (
                        <div className="leads-summary-wrapper">
                          <Card className="top-margin auto-height">
                            <CardBody className="card-sm top-border">
                              <div className="leads-summary-container">
                                <div className="leads-summary-header">
                                  <div className="leads-summary-header-groups tooltip-title">Leads ({this.state.leadGroupTimeframe} Days) &mdash; {leadsSummaryData.filter(lead => lead['LeadScoreGroup'+this.state.leadGroupTimeframe+'Days'] === 'Hot').length.toLocaleString("en-US")} Hot, {leadsSummaryData.filter(lead => lead['LeadScoreGroup'+this.state.leadGroupTimeframe+'Days'] === 'Warm').length.toLocaleString("en-US")} Warm, {leadsSummaryData.filter(lead => lead['LeadScoreGroup'+this.state.leadGroupTimeframe+'Days'] === 'Cool').length.toLocaleString("en-US")} Cool <span className="tooltip-text">Leads are scored daily based on web and email&nbsp;data. <span className="tooltip-arrow" /></span></div>
                                  <div className="leads-summary-header-activity">Lead Activity &mdash;&nbsp; 
                                    {leadsData.filter(row => row.EventType === 'Click Email Link' && row.EventDate >= this.state.initialTimeframeDate).length > 0 &&
                                      <span className="summary-activity">Click Email Links: {leadsData.filter(row => row.EventType === 'Click Email Link' && row.EventDate >= this.state.initialTimeframeDate).length.toLocaleString("en-US")}</span>
                                    }
                                    {leadsData.filter(row => row.EventType === 'View Page' && row.EventDate >= this.state.initialTimeframeDate).length > 0 &&
                                      <span className="summary-activity">Web Page Views: {leadsData.filter(row => row.EventType === 'View Page' && row.EventDate >= this.state.initialTimeframeDate).length.toLocaleString("en-US")}</span>
                                    }
                                    {leadsData.filter(row => row.EventType === 'Submit Form' && row.EventDate >= this.state.initialTimeframeDate).length > 0 &&
                                      <span className="summary-activity">Form Submissions: {leadsData.filter(row => row.EventType === 'Submit Form' && row.EventDate >= this.state.initialTimeframeDate).length.toLocaleString("en-US")}</span>
                                    }
                                    {leadsData.filter(row => row.EventType === 'Watch Video' && row.EventDate >= this.state.initialTimeframeDate).length > 0 &&
                                      <span className="summary-activity">Videos Watched: {leadsData.filter(row => row.EventType === 'Watch Video' && row.EventDate >= this.state.initialTimeframeDate).length.toLocaleString("en-US")}</span>
                                    }
                                  </div> 
                                </div>
                                <div className="leads-summary-export">
                                  <div className="leads-export-title">
                                    <span type="button" id="leads-whats-new-btn" className={"leads-whats-new-btn tooltip-title tooltip-btn"} onClick={() => this.toggleWhatsNew()}><AlertDecagramIcon /><span className="sr-only">Show what&rsquo;s new</span> 
                                    <span className="tooltip-text">{popoverContent.whatsNewIcon} <span className="tooltip-arrow" /></span>
                                      {showWhatsNewAlert &&
                                        <span id="leads-whats-new-alert" className="leads-whats-new-alert">
                                          <span className="tooltip-arrow" />
                                          Click above to see what&rsquo;s new on the leads&nbsp;page!
                                        </span>
                                      }
                                    </span>
                                    <span type="button" className={"leads-export-toggle-btn tooltip-title tooltip-btn"} onClick={()=> this.toggleExportMenu()}><ExportVariantIcon /><span className="sr-only">Toggle export menu</span>
                                    <span className="tooltip-text">{popoverContent.exportIcon} <span className="tooltip-arrow" /></span>
                                    </span>
                                  </div>
                                  <div className={"leads-export-menu show-" + this.state.showExportMenu} >
                                    <button className="leads-export-btn" onClick={()=> this.exportLeads('leads-summary-table')}>Export Summary as CSV</button>
                                    <button className="leads-export-btn" onClick={()=> this.exportLeads('leads-summary-details')}>Export All Details as CSV</button>
                                  </div>
                                  <Modal 
                                    isOpen={showWhatsNew} 
                                    target='leads-whats-new-btn' 
                                    className='leads-whats-new-modal'
                                    scrollable={true}
                                    toggle={() => this.toggleWhatsNew()}>
                                    <ModalHeader toggle={() => this.toggleWhatsNew()}>What&rsquo;s new on the leads page?</ModalHeader>
                                    <ModalBody>
                                      <p>These leads page improvements make finding your best leads even easier.</p>
                                      <ul>
                                        <li key="lead-info-1"><strong>Adjust lead time frame</strong>: Choose to focus on either 90 or 365 days of activity.</li>
                                        <li key="lead-info-2"><strong>Drill in place</strong>: Drill directly into a lead&rsquo;s activity by clicking on their name or email address. Return to the summary view by clicking the back button next to the lead&rsquo;s name.</li>
                                        <li key="lead-info-3"><strong>New fields</strong>: When drilling into a lead&rsquo;s details, you will see the lead&rsquo;s state or region they visited your website from as well as any campaign information that was used on links. You can also see their Donor/Constituent ID from your system if their activity is through a Stelter email or survey send.</li>
                                        <li key="lead-info-4"><strong>New activities</strong>: New web interactions are included in the lead activity listing. Examples include designation for when the lead has completed a gift intention form, interacts with the DAF or IRA Fund Finder widgets, saves a Gift Illustrator calculation, or downloads/prints a digital guide.</li>
                                        <li key="lead-info-5"><strong>Filter by visit source</strong>: Visit sources can now be used to filter leads by channels. New columns in the lead details show more specific info about the campaigns. Further filtering could be done by exporting all details as CSV, then opening in Excel.</li>
                                        <li key="lead-info-6"><strong>More export options</strong>: There are now three options for export. Export the summary listing of leads, export all leads and their activities, or drill into a lead and export just their activity.</li>
                                      </ul>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                          <Card className="top-margin auto-height">
                            <CardBody className="card-nopad tall-table">
                              <div className="table-wrapper leads-table-wrapper">
                                <table id="leads-summary-table" className="leads-table leads-summary filter-table">
                                  <thead>
                                    <tr>
                                      <th><span className="th-inner"><span className={'sort-arrows ' + this.getClassNamesFor('FullName')} onClick={() => {this.sortTable('FullName')}} /><span className="col-name">Name</span></span></th>
                                      <th><span className="th-inner"><span className={'sort-arrows ' + this.getClassNamesFor('EmailAddress')} onClick={() => {this.sortTable('EmailAddress')}} /><span className="col-name">Email Address</span></span></th>
                                      <th className="cell-align-center"><span className="th-inner"><span className={'sort-arrows ' + this.getClassNamesFor('DonorID')} onClick={() => {this.sortTable('DonorID')}} /><span className="col-name">Donor ID</span></span></th>
                                      <th className="cell-align-center"><span className="th-inner"><span className={'sort-arrows ' + this.getClassNamesFor('LeadScoreGroup'+leadGroupTimeframe+'Days')} onClick={() => {this.sortTable('LeadScoreGroup'+leadGroupTimeframe+'Days')}} /><span className="col-name">Lead Group</span></span></th>
                                      <th className="cell-align-center"><span className="th-inner"><span className={'sort-arrows ' + this.getClassNamesFor('GiftIntentionSimple')} onClick={() => {this.sortTable('GiftIntentionSimple')}} /><span className="col-name">Gift Intention</span></span></th>
                                      <th><span className="th-inner"><span className={'sort-arrows ' + this.getClassNamesFor('LastActivityDate')} onClick={() => {this.sortTable('LastActivityDate')}} /><span className="col-name">Last Activity Date</span></span></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.currentLeads.length > 0 ? (
                                        currentLeads.map((row, index) => {
                                          return (
                                            <tr key={row.DateTime + '_' + index}>
                                              <td className="cell-align-left drill-cell fullname-col" style={{minWidth: "150px"}}><span className="table-drill-btn" onClick={() => this.drillToNameEmail(row.NameEmail)}>{row["FullName"]}</span></td>
                                              <td className="cell-align-left drill-cell emailaddress-col" style={{minWidth: "150px"}}><span className="table-drill-btn" onClick={() => this.drillToNameEmail(row.NameEmail)}>{row["EmailAddress"]}</span></td>
                                              <td className="cell-align-center donorid-col">{row["DonorID"]}</td>
                                              <td className={"cell-align-center lead-group-col lead-group-" + row["LeadScoreGroup"+leadGroupTimeframe+"Days"].toLowerCase().replace(/\s/g, '')}>{row["LeadScoreGroup"+leadGroupTimeframe+"Days"]}</td>
                                              <td className={"cell-align-center intention-col intention-" + row["GiftIntentionSimple"].toLowerCase()}>{row['GiftIntentionSimple']}</td>
                                              <td className="cell-align-left lastactivity-col">{new Date(row["LastActivityDate"]).toLocaleString()}</td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td colSpan="100%">No leads match the filters selected. Try selecting 365 day timeframe.</td>
                                        </tr>
                                      )
                                    }
                                  </tbody>
                                  <caption>
                                    
                                  </caption>
                                </table>
                              </div>
                              {pages.length > 1 &&
                                  <nav id="leads-table-nav">
                                    <span className="footer-nav-descriptor">Page: </span>
                                    {
                                      pages.map(page => {
                                        return page === this.state.summaryPage ? (
                                          <span key={"page-"+page} className={"footer-nav-link current-page"}>{page}</span>
                                        ) : (
                                          <span key={"page-"+page} className={"footer-nav-link"} onClick={()=> this.setSummaryPage(page)}>{page}</span>
                                        )
                                      })
                                    }
                                  </nav>
                                }
                            </CardBody>
                          </Card>
                        </div>
                      ) : (
                        <div className="leads-summary-wrapper">
                          <Card className="top-margin auto-height">
                            <CardBody className="card-sm top-border">
                              <div className="leads-summary-container">
                                <div className="leads-summary-header">
                                  <div className="leads-header-name tooltip-title">
                                    <div><span className="back-btn" onClick={() => {
                                  this.drillToNameEmail(''); detailsDateArr = [];}}><ArrowLeftCircleIcon /></span> <span className="drill-donor-name">{drillNameEmail}</span> &mdash; <span className="drill-donor-id">Donor ID: {
                                    leadData[0].DonorID !== '' ?
                                    leadData[0].DonorID :
                                    'Unknown'
                                    }</span> &mdash; {leadData[0]["LeadScoreGroup"+leadGroupTimeframe+"Days"]} Lead ({leadGroupTimeframe} Days)</div>
                                  </div>
                                  <div className="leads-header-details">
                                    {leadData.filter(row => row.EventType === 'Open Email').length > 0 &&
                                      <span className="summary-activity">Opens: {leadData.filter(row => row.EventType === 'Open Email').length.toLocaleString("en-US")}</span>
                                    }
                                    {leadData.filter(row => row.EventType === 'Click Email Link').length > 0 &&
                                      <span className="summary-activity">Clicks: {leadData.filter(row => row.EventType === 'Click Email Link').length.toLocaleString("en-US")}</span>
                                    }
                                    {leadData.filter(row => row.EventType === 'View Page').length > 0 &&
                                      <span className="summary-activity">Web Pages: {leadData.filter(row => row.EventType === 'View Page').length.toLocaleString("en-US")}</span>
                                    }
                                    {leadData.filter(row => row.EventType === 'Submit Form').length > 0 &&
                                      <span className="summary-activity">Form Submissions: {leadData.filter(row => row.EventType === 'Submit Form').length.toLocaleString("en-US")}</span>
                                    }
                                    {leadData.filter(row => row.EventType === 'Watch Video').length > 0 &&
                                      <span className="summary-activity">Videos: {leadData.filter(row => row.EventType === 'Watch Video').length.toLocaleString("en-US")}</span>
                                    }
                                  </div> 
                                </div>
                                <div className="leads-summary-export">
                                  <div className="leads-export-title">
                                    {this.state.isLocal &&
                                      <span type="button" className={"leads-edit-btn"} onClick={()=> this.toggleEditMenu()}><PencilIcon /><span className="sr-only">Toggle edit menu</span></span>
                                    }
                                    <span type="button" className={"leads-export-toggle-btn"} onClick={()=> this.toggleExportMenu()}><ExportVariantIcon /><span className="sr-only">Toggle export menu</span></span>
                                  </div>
                                  <div className={"leads-export-menu show-" + this.state.showExportMenu} >
                                    <button className="leads-export-btn" onClick={()=> this.exportLeads('lead-details', leadData)}>Export Lead Details as CSV</button>
                                  </div>
                                  {this.state.isLocal &&
                                    <div className={"leads-edit-menu show-" + this.state.showEditMenu} >
                                      <p>Edit options:</p>
                                      <ul>
                                        <li>Remove lead</li>
                                        <li>Assign lead group</li>
                                        <li>Export lead to GiftClarity</li>
                                      </ul>
                                    </div>
                                  }
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                          <Card className="top-margin auto-height">
                            <CardBody className="card-nopad tall-table">
                              <div className="table-wrapper leads-table-wrapper">
                                <table id="leads-detail-table" className="leads-table leads-details">
                                  <thead>
                                    <tr>
                                      <th><span className="th-inner">Date</span></th>
                                      <th><span className="th-inner">Time</span></th>
                                      <th><span className="th-inner">Activity</span></th>
                                      <th><span className="th-inner">Content</span></th>
                                      <th><span className="th-inner">URL Path</span></th>
                                      <th><span className="th-inner">State/<wbr/>Region</span></th>
                                      {/* <th><span className="th-inner">Referring Domain</span></th> */}
                                      <th><span className="th-inner">Visit Source</span></th>
                                      <th><span className="th-inner">Campaign Name</span></th>
                                      <th><span className="th-inner">Campaign Info</span></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {leadsData !== '' &&
                                      leadsData.filter(row => row.NameEmail === drillNameEmail).map((row, index) => {
                                        
                                        let dateTime = row["EventDate"];
                                        let dateTimeArr = dateTime.toLocaleDateString('en-US', { timeZone: 'America/Chicago' }).split('/');
                                        let dateMonth = parseInt(dateTimeArr[0]) > 9 ? dateTimeArr[0] : "0" + dateTimeArr[0];
                                        let dateDay = parseInt(dateTimeArr[1]) > 9 ? dateTimeArr[1] : "0" + dateTimeArr[1];
                                        let dateYear = dateTimeArr[2].slice(2,4);
                                        let dateStr = dateMonth + '-' + dateDay + '-' + dateYear;
                                        if(detailsDateArr.indexOf(dateStr) === -1) {
                                          detailsDateArr.push(dateStr) 
                                        } else {
                                          dateStr = '';
                                        }
                                        let timeStr = dateTime.toLocaleTimeString('en-US');
                                        return (
                                          <tr key={row.DateTime + '_' + index}>
                                            <td className="cell-align-left" style={{minWidth: "100px"}}>{dateStr}</td>
                                            <td className="cell-align-left" style={{minWidth: "100px"}}>{timeStr}</td>
                                            <td className="cell-align-left" style={{minWidth: "100px"}}>{row["EventType"]}</td>
                                            <td className="cell-align-left" style={{minWidth: "250px"}} dangerouslySetInnerHTML={{ __html: row["ScoreContent"].replace(/\\n/g,'<br/>') }}>
                                            </td>
                                            <td className="cell-align-left" style={{minWidth: "100px"}}>{row["URL_Path"]}</td>
                                            <td className="cell-align-left">{row["State_Geo_Region"].toUpperCase()}</td>
                                            {/* <td className="cell-align-left">{row["ReferringDomain"]}</td> */}
                                            <td className="cell-align-left">{row["VisitSource"]}</td>
                                            <td className="cell-align-left">{row["CampaignName"]}</td>
                                            <td className="cell-align-left">{row["CampaignInfo"]}</td>
                                          </tr>
                                        );
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      )
                      }
                    </Col>
                  </Row>
                </div>
              )
            }  
          </div>
        </Card>
      </Col>
		);
	}
}

export default Content;