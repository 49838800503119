import API_HOST from './ApiHost';
// import {
//   BlobServiceClient,
//   StorageSharedKeyCredential,
//   BlobDownloadResponseModel
// } from "@azure/storage-blob";
// Load the .env file if it exists
import {
  BlobServiceClient
} from "@azure/storage-blob";
import { setLogLevel } from "@azure/logger";
//import * as dotenv from "dotenv";

//dotenv.config();
setLogLevel("info");

export const getOidcStorageKey = () => {
    const authSettings = JSON.parse(localStorage.getItem('authSettings'));
    if (authSettings) {
        return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
    }
    return null;
};

export const getOidcInfo = () => {
    const key = getOidcStorageKey();
    if (key) {
        const oidc = JSON.parse(localStorage.getItem(key));
        return oidc;
    }
    return null;
};

export const getToken = () => {
    const oidc = getOidcInfo();
    if (oidc) {
        return oidc.id_token;
    }
    return null;
};

export const aggByKey = (arr, prop = 'Date') => {
	const map = new Map(Array.from(arr, obj => [obj[prop], []]));
	arr.forEach(obj => map.get(obj[prop]).push(obj));
	var array = Array.from(map.values());
	
	var array2 = array.map(row => {
		var newRow = {};
		newRow[prop] = row[0][prop];
		newRow.Count = row.length;
		return newRow;
	})
	return array2;
}

export const arrsToJson = (arr, cols) => {
  return arr.map(row => {
    let newRow = {}
    row.forEach((obj, index) => newRow[cols[index]] = row[index])
    return newRow;
  })
}

export const throttle = (func, delay) => {
  let inProgress = false;
  return (...args) => {
    if (inProgress) {
      return;
    }
    inProgress = true;
    setTimeout(() => {
      func(...args); // Consider moving this line before the set timeout if you want the very first one to be immediate
      inProgress = false;
    }, delay);
  }
}

export async function fetchEmbed(embedItem, pFilters, isAdmin) {
    pFilters = pFilters || '';
    var pFilterQuery = '';
    if(pFilters !== '') {
      pFilters = pFilters.replace(/"/g,'%22').replace(/ /g,'%20');
      pFilterQuery = `?pfilters=${pFilters}`;
    } else if (window.location.search.indexOf('pfilters') > -1) {
      var queryParams = new URLSearchParams(window.location.search);
      pFilters = queryParams.get('pfilters').replace(/"/g,'%22').replace(/ /g,'%20');
      pFilterQuery = `?pfilters=${pFilters}`;
    }
    // pFilters === '' ? '' : `?pfilters=${encodeURIComponent(pFilters)}`;
    if (isAdmin && pFilterQuery !== '') console.log(pFilterQuery)

    if (embedItem.toString().indexOf('custom') === -1) {
      // listen for iframe height
      window.onmessage = (event) => {
        //if we get some data back
        if (event.data && event.data.method === 'notifyFrameSize' && event.data.params && event.data.params.height) {
            var height = Math.round(event.data.params.height) + 10;
            // update .iframe-wrapper height
            this.setState({
              iframeHeight: height + 'px'
            });
        }
      }
    }
    
    return await new Promise(function(resolve) {
      fetch(`${API_HOST}/api/embed/items/${embedItem}${pFilterQuery}`,{
        headers: {"accessToken":sessionStorage.getItem('domoToken')},
        credentials: 'include'
      }).then(response => {
          if (!response.ok) throw Error(response.status);
          return response.text()
        })
        .then(data => { 
          resolve(data) 
        })
        .catch((e) => {
          var error = "<p>Sorry, there was an error retrieving the data. Please refresh the page to try again.</p>";
          //console.error("An error occurred while processing the request.", e, e.message, e.error);
          if (isAdmin) console.error("An error occurred while processing the request.", e, e.message);
          resolve(error);
        }); 
    });
}

export async function getData(accountID, isAdmin, datasetID, query, pFilters) {
  let dataQuery = {sql: query} || {sql: 'SELECT * FROM table'};
  pFilters = pFilters || '';
  var pFilterQuery = '';
  if(pFilters !== '') {
    pFilters = pFilters.replace(/"/g,'%22').replace(/ /g,'%20');
    pFilterQuery = `?pfilters=${pFilters}`;
  } else if (window.location.search.indexOf('pfilters') > -1) {
    var queryParams = new URLSearchParams(window.location.search);
    pFilters = queryParams.get('pfilters').replace(/"/g,'%22').replace(/ /g,'%20');
    pFilterQuery = `?pfilters=${pFilters}`;
  }
  // pFilters === '' ? '' : `?pfilters=${encodeURIComponent(pFilters)}`;
  if (isAdmin && pFilterQuery !== '') console.log(pFilterQuery)

  //console.log(dataQuery)
  return await new Promise(async (resolve) => {
    await fetch(`${API_HOST}/api/getDataset/${datasetID}${pFilterQuery}`,{
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        "accessToken":sessionStorage.getItem('domoToken'),
        "accountID": accountID
      },
      credentials: 'include',
      body: JSON.stringify(dataQuery)
    }).then(res => res.json())
    .then(data => { 
      resolve(data)
    })
    .catch((e) => {
      var error = "<p>Sorry, there was an error retrieving the data. Please refresh the page to try again.</p>";
      //console.error("An error occurred while processing the request.", e, e.message, e.error);
      if (isAdmin) console.error("An error occurred while processing the request.", e);
      resolve(error);
    }); 
  });

}

// async function listblob(containerClient, prefix) {
//   let iter1 = containerClient.listBlobsByHierarchy("/", { prefix: prefix });
//   for await (const item of iter1) {
//     if (item.kind === "prefix") {
//       console.log(`\tBlobPrefix: ${item.name}`);
//     } else {
//       console.log(`\tBlobItem: name - ${item.name}`);
//     }
//   }
// }

export async function listFiles(accountID, filepath) {
  filepath = filepath || '';
  // // Enter your storage account name and shared key
  // const account = process.env.REACT_APP_FILES_ACCOUNT_NAME || "";
  // const accountKey = process.env.REACT_APP_FILES_ACCOUNT_KEY || "";

  // // Use StorageSharedKeyCredential with storage account and account key
  // // StorageSharedKeyCredential is only available in Node.js runtime, not in browsers
  // const sharedKeyCredential = new StorageSharedKeyCredential(account, accountKey);

  // // List containers
  // const blobServiceClient = new BlobServiceClient(
  //   // When using AnonymousCredential, following url should include a valid SAS or support public access
  //   `https://${account}.blob.core.windows.net`,
  //   sharedKeyCredential
  // );

  const blobSasUrl = process.env.REACT_APP_FILES_SAS || '';
  const blobServiceClient = new BlobServiceClient(blobSasUrl);

  const containerClient = blobServiceClient.getContainerClient('root2');
  //const blobClient = containerClient.getBlobClient(`/${accountID}`);

  // Create a blob
  // const content = "hello, 你好";
  // const blobName = "newblob" + new Date().getTime();
  // const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  // const uploadBlobResponse = await blockBlobClient.upload(content, Buffer.byteLength(content));
  // console.log(`Upload block blob ${blobName} successfully`, uploadBlobResponse.requestId);

  // List blobs
  // let i = 1;
  // for await (const blob of containerClient.listBlobsFlat()) {
  //   console.log(`Blob ${i++}: ${blob.name}`);
  // }

  var folders = [];
  var files = [];
  var accountPrefix = `Clients/${accountID}/`;
  console.log("list blobs with method listBlobsByHierarchy");
  let iter1 = containerClient.listBlobsByHierarchy("/", { prefix: `${accountPrefix}${filepath}` });
  for await (const item of iter1) {
    if (item.kind === "prefix") {
      console.log(`\tBlobPrefix: ${item.name}`);
      console.log(item)
      var folderPath = item.name.split(accountPrefix)[1];
      let displayName = folderPath.endsWith('/') ? folderPath.slice(0,-1).split('/').pop() : folderPath.split('/').pop();
      var folderInfo = {path: folderPath, name: displayName};
      folders.push(folderInfo)
      //var blob = await listblob(containerClient, item.name);
      // let iter2 = containerClient.listBlobsByHierarchy("/", { prefix: item.name });
      //   for await (const item2 of iter2) {
      //     if (item2.kind === "prefix") {
      //       console.log(`\tBlobPrefix: ${item2.name}`);
      //       folders.push(item2.name)
      //     } else {
      //       console.log(`\tBlobItem: name - ${item2.name}`);
      //       files.push(item2.name)
      //     }
      //   }
    } else {
      if(item.name !== accountPrefix) {
        console.log(`\tBlobItem: name - ${item.name}`);
        console.log(item)
        var metaData = typeof item.metadata !== 'undefined' ? item.metadata : '';
        var lastModifiedDate = (new Date(JSON.stringify(item.properties.lastModified).split('"')[1])).toLocaleString('en-US');
        var lastModified = JSON.stringify(item.properties.lastModified) === JSON.stringify(item.properties.createdOn) ? 'Created ' +  lastModifiedDate : 'Updated ' + lastModifiedDate;
        var contentType = item.properties.contentType;
        var fileSize = item.properties.contentLength;
        fileSize = fileSize > (1000 * 1000) ? (Math.round((fileSize / (1000 * 1000)) * 10) / 10) + ' MB' : fileSize > 1000 ? Math.floor(fileSize / 1000) + ' KB' : fileSize + ' bytes';

        var fileType = 'document';
        if (contentType === 'application/pdf') {
          fileType = 'pdf'
        } else if (contentType.indexOf('excel') > -1 || contentType.indexOf('spreadsheet') > -1 || contentType.toLowerCase().startsWith('text/csv')) {
          fileType = 'spreadsheet'
        } else if (contentType.toLowerCase().startsWith('image')) {
          fileType = 'image'
        } else if (contentType.toLowerCase().startsWith('video')) {
          fileType = 'video'
        } else if (contentType === 'application/zip') {
          fileType = 'zip'
        } else {
          fileType = 'document'
        }

        let displayName = item.name.split('/').pop();

        var fileInfo = {path: item.name.split(accountPrefix)[1], name: displayName, type: fileType, filesize: fileSize, metadata: metaData, lastModified: lastModified}
        if(item.properties.contentType === 'application/octet-stream' && item.properties.contentLength === 0) {
          // This is an empty blob, do not send
        } else {
          files.push(fileInfo)
        }
        
      } 
    }
  }

  // Get blob content from position 0 to the end
  // In Node.js, get downloaded data by accessing downloadBlockBlobResponse.readableStreamBody
  // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
  // const downloadBlockBlobResponse: BlobDownloadResponseModel = await blockBlobClient.download(0);
  // console.log(
  //   "Downloaded blob content",
  //   (await streamToBuffer(downloadBlockBlobResponse.readableStreamBody!)).toString()
  // );

  // // Delete container
  // await containerClient.delete();

  // console.log("deleted container");

  var filesFolders = {folders: folders, files: files}
  return filesFolders
}

// [Browsers only] A helper method used to convert a browser Blob into string.
// async function blobToString(blob) {
//   const fileReader = new FileReader();
//   return new Promise((resolve, reject) => {
//     fileReader.onloadend = (ev) => {
//       resolve(ev.target.result);
//     };
//     fileReader.onerror = reject;
//     fileReader.readAsText(blob);
//   });
// }

async function downloadBlob(filename,downloadBlockBlobResponse) {
  let blob = await downloadBlockBlobResponse.blobBody;
  const link = document.getElementById('hidden-link');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  link.href = '/';
  link.download = '';
}

export async function downloadFile(accountID, filepath) {
  filepath = filepath || '';
  console.log(filepath);
  const blobSasUrl = process.env.REACT_APP_FILES_SAS || '';
  const blobServiceClient = new BlobServiceClient(blobSasUrl);

  const containerClient = blobServiceClient.getContainerClient('root2');
  const blobClient = containerClient.getBlobClient(`Clients/${accountID}/${filepath}`);
  const filename = filepath.split('/').pop();

  // Get blob content from position 0 to the end
  // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
  try {
    const downloadBlockBlobResponse = await blobClient.download({ onProgress: ev => console.log(ev) });
    await downloadBlob(filename, downloadBlockBlobResponse);
    console.log("Downloaded blob content", filename);
    return 'download success';
  } catch (e) {
    console.log(e);
    return 'download error'
  }
  
}

export async function fetchFilesEmbed(accountID) {


  // listen for iframe height
  // window.onmessage = (event) => {
  //   //if we get some data back
  //   if (event.data && event.data.method === 'notifyFrameSize' && event.data.params && event.data.params.height) {
  //       var height = Math.round(event.data.params.height) + 10;
  //       // update .iframe-wrapper height
  //       this.setState({
  //         iframeHeight: height + 'px'
  //       });
  //   }
  // }

  // return await new Promise(function(resolve) {
    // fetch(`${API_HOST}/api/files`,{
    //   headers: {"accessToken":sessionStorage.getItem('domoToken')},
    //   credentials: 'include'
    // }).then(response => {
    //     if (!response.ok) throw Error(response.status);
    //     return response.text()
    //   })
    //   .then(data => { 
    //     resolve(data) 
    //   })
    //   .catch((e) => {
    //     var error = "<p>Sorry, there was an error retrieving the data. Please refresh the page to try again.</p>";
    //     //console.error("An error occurred while processing the request.", e, e.message, e.error);
    //     console.error("An error occurred while processing the request.", e, e.message);
    //     resolve(error);
    //   }); 
  // });

}

export async function fetchExavaultEmbed(accountID) {


  // listen for iframe height
  // window.onmessage = (event) => {
  //   //if we get some data back
  //   if (event.data && event.data.method === 'notifyFrameSize' && event.data.params && event.data.params.height) {
  //       var height = Math.round(event.data.params.height) + 10;
  //       // update .iframe-wrapper height
  //       this.setState({
  //         iframeHeight: height + 'px'
  //       });
  //   }
  // }

  return await new Promise(function(resolve) {
    fetch(`${API_HOST}/api/exavault`,{
      headers: {"accessToken":sessionStorage.getItem('domoToken')},
      credentials: 'include'
    }).then(response => {
        if (!response.ok) throw Error(response.status);
        return response.text()
      })
      .then(data => { 
        resolve(data) 
      })
      .catch((e) => {
        var error = "<p>Sorry, there was an error retrieving the data. Please refresh the page to try again.</p>";
        //console.error("An error occurred while processing the request.", e, e.message, e.error);
        console.error("An error occurred while processing the request.", e, e.message);
        resolve(error);
      }); 
  });

}

export async function checkDataStatus() {
  return await new Promise((resolve) => {
    fetch(`${API_HOST}/api/datastatus`).then(response => {
        if (!response.ok) {
            resolve({status:`error`,message:`There was an issue detecting the data server's status. Please check back later.`})
        }
        return response.json();
      })
      .then(data => { 
        resolve(data)
      })
      .catch((e) => {
          console.error(`An error occurred while processing the request.`, e, e.message, e.error);
          resolve({status:`error`,message:`There was an issue detecting the data server's status. Please check back later.`});
      });
  });
}