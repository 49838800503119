import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Content from './components/DirectMailContent';
import PageTooltip from '../../../shared/PageTooltip';

const Page = () => {
  const [refreshTime, setRefreshTime] = useState(0);

  return(
    <div className="container__wrap">
      <Container className="dashboard">
        <PageTooltip state={{ refreshTime: [refreshTime, setRefreshTime] }}/>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Direct Mail Results</h3>
          </Col>
        </Row>
        <Row>
          <Content key={refreshTime}/>
        </Row>
      </Container>
    </div>
  );
};

export default Page;
