import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Content from './components/FilesContent';

const Page = () => (
  <div className="container__wrap files-page">
    {/* <Content /> */}
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Files</h3>
        </Col>
      </Row>
          <Content />
    </Container>
  </div>
);

export default Page;
