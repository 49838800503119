import React, { useContext, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { store } from '../App/store';
import MicrosoftWindowsIcon from 'mdi-react/MicrosoftWindowsIcon';
//import LoginForm from './components/LoginForm';
import TopbarAlert from '../Layout/topbar/TopbarAlert';
import Cookies from 'universal-cookie';
import API_HOST from '../../utils/ApiHost';
const cookies = new Cookies();

const Login = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { state } = useLocation();
  //const { accountID, accountName, userGroup, isAuthenticated } = globalState.state;

  useEffect(() => {
    const allCookies = cookies.getAll();
    Object.keys(allCookies).forEach(key => {
      if(key !== 'authRedirect') cookies.remove(key, {path: '/', secure: true})
    })
    // Remove authRedirect cookie if coming from logout or clearcache link
    if (
      (typeof state !== 'undefined' && (state.prevPath.includes('clearcache') || state.prevPath.includes('logout'))) ||
      (typeof cookies.get('authRedirect') !== 'undefined' && (cookies.get('authRedirect').includes('clearcache') || cookies.get('authRedirect').includes('logout')))
    ) {
      cookies.remove('authRedirect', {path: '/', secure: true})
    }

    dispatch({ type: 'SET_ACCOUNT_ID', value: '' });
    dispatch({ type: 'SET_ACCOUNT_NAME', value: '' });
    dispatch({ type: 'SET_USER_GROUP', value: '' });
    dispatch({ type: 'SET_USER_AUTH', value: false });
    dispatch({ type: 'SET_AUTH_REDIRECT', value: '' });
    dispatch({ type: 'SET_PG_WEBSITE', value: '' });
    dispatch({ type: 'SET_WEB_CLIENT', value: false });
    dispatch({ type: 'SET_EMAIL_CLIENT', value: false });
    dispatch({ type: 'SET_SURVEY_CLIENT', value: false });
    dispatch({ type: 'SET_LEGACY_SURVEY_CLIENT', value: false });
    dispatch({ type: 'SET_GIVING_DOCS_CLIENT', value: false });
    dispatch({ type: 'SET_PLATFORM_CLIENT', value: false });
  }, [dispatch, state]);  

  const setDemo = () => {
    cookies.set('demo', 'true', { path: '/', secure: true});
  }

  return (
    <div className="account">
      <TopbarAlert />
      <div className="account__wrapper">
        <div className="account__card">
          <span className="login__logo"><span className="sr-only">Stelter Intelligence Center</span></span>
          {window.location.hostname.includes('localhost') &&
            <div style={{textAlign: "center"}}>Local Development</div>
          }
          <p><a className="btn btn-primary btn__flex-icon btn-login" href={`${API_HOST}/api/ad-login`}>
          <MicrosoftWindowsIcon /> Sign in with Stelter Client Directory
          </a></p>
          {/* <h4>Questions?</h4>
          <p>Please contact your customer success manager.</p> */}
        </div>
        <p className="text-center">&copy; The Stelter Company &bull; <a className="link-inherit" href={`${API_HOST}/api/internal-login`}>Internal Access</a> &bull; <a className="link-inherit" href={`${API_HOST}/api/internal-login?demo=true`} onClick={setDemo}>Demo</a></p>
      </div>
    </div>
  );
};
export default Login;
