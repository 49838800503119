import React, { PureComponent } from 'react';
import { Formik, Form } from 'formik';
import { Redirect } from 'react-router-dom';
import { store } from '../../App/store';
import API_HOST from '../../../utils/ApiHost';
import Downshift from 'downshift';
//import Dexie from "dexie";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const ArrowIcon = ({isOpen}) => {
    return (
        <svg
            viewBox="0 0 20 20"
            preserveAspectRatio="none"
            width={16}
            fill="transparent"
            stroke="#979797"
            strokeWidth="1.1px"
            transform={isOpen ? 'rotate(180)' : undefined}
        >
        <path d="M1,6 L10,15 L19,6" />
        </svg>
    )
}
const XIcon = () => {
    return (
        <svg
            viewBox="0 0 20 20"
            preserveAspectRatio="none"
            width={12}
            fill="transparent"
            stroke="#979797"
            strokeWidth="1.1px"
        >
        <path d="M1,1 L19,19" />
        <path d="M19,1 L1,19" />
        </svg>
    )
}
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

class AccountSelect extends PureComponent {
    static contextType = store;

    constructor() {
        super();
        this.state = {
            loading: false,
            thisIsAuthenticated: false,
            token: '',
            selectedAccount: '',
            accountID: '',
            accountName: '',
            pgWebsite: '',
            accounts: []
        };
    }

    handleStateChange = (changes) => {
        if (changes.hasOwnProperty('selectedItem')) {
            this.setState({value: changes.selectedItem})
        } else if (changes.hasOwnProperty('inputValue')) {
            this.setState({value: changes.inputValue})
        }
    }

    componentDidMount = async () => {
        const globalState = this.context;
        const { userGroup, dispatch } = globalState;
        const isAdmin = userGroup === 'Admin';
        cookies.remove('demo', {path: '/', secure: true});
        cookies.remove('accountID', {path: '/', secure: true});
        cookies.remove('accountName', {path: '/', secure: true});
        cookies.remove('pgWebsite', {path: '/', secure: true});
        cookies.remove('webClient', {path: '/', secure: true});
        cookies.remove('emailClient', {path: '/', secure: true});
        cookies.remove('surveyClient', {path: '/', secure: true});
        cookies.remove('customSurveyClient', {path: '/', secure: true});
        cookies.remove('legacySurveyClient', {path: '/', secure: true});
        cookies.remove('givingDocsClient', {path: '/', secure: true});
        cookies.remove('platformClient', {path: '/', secure: true});

        // TODO review the internal cookie removal
        // const allCookies = cookies.getAll();
        // const cookiesToKeep = ['active','user','userE','userGroup','userBrowser','domoToken','isAuthenticated','multipleAccounts','timeoutDuration'];
        // Object.keys(allCookies).forEach(key => {
        //     if(cookiesToKeep.indexOf(key) === -1) {
        //         cookies.remove(key, {path: '/', secure: true})
        //     }
        // })
        
        dispatch({ type: 'SET_ACCOUNT_ID', value: '' });
        dispatch({ type: 'SET_ACCOUNT_NAME', value: '' });
        dispatch({ type: 'SET_PG_WEBSITE', value: '' });
        dispatch({ type: 'SET_WEB_CLIENT', value: false });
        dispatch({ type: 'SET_EMAIL_CLIENT', value: false });
        dispatch({ type: 'SET_SURVEY_CLIENT', value: false });
        dispatch({ type: 'SET_CUSTOM_SURVEY_CLIENT', value: false });
        dispatch({ type: 'SET_LEGACY_SURVEY_CLIENT', value: false });
        dispatch({ type: 'SET_GIVING_DOCS_CLIENT', value: false });
        dispatch({ type: 'SET_PLATFORM_CLIENT', value: false });
        dispatch({ type: 'SET_LEADS_DATA', value: '' });
        dispatch({ type: 'SET_LEADS_SUMMARY', value: '' });
        dispatch({ type: 'SET_LEADS_ACTIVITIES', value: '' });
        dispatch({ type: 'SET_TOTAL_LEADS', value: 0 });

        console.log('request accounts:')
        let response = await fetch(`${API_HOST}/api/listaccounts`,{
            method: 'post',
            credentials: 'include'
        });
        let data = await response.json();
        console.log('accounts received')
        /* 
        // Revisit when Firefox allows indexedDB.databases()
        const dbs = await indexedDB.databases()
        dbs.forEach(db => { indexedDB.deleteDatabase(db.name) })
        */
        if (isAdmin) console.log('delete db')
        const dbDelete = indexedDB.deleteDatabase('Leads');
        dbDelete.onerror = (event) => {
            if (isAdmin) {
                console.error("Error deleting database.");
            }
        };
        dbDelete.onsuccess = (event) => {
            if (isAdmin) {
                console.log("Database deleted successfully");
            }
        };
        window.domoToken = data.domoToken;
        window.__RYUU_AUTHENTICATION_TOKEN__ = data.domoToken;
        this.setState({ token: data.domoToken, accounts: data.accounts, accountUsers: data.accountUsers });
    }

    render() {
        const globalState = this.context;
        const { dispatch } = globalState;
        const { authRedirect, userGroup } = globalState.state;
        const { thisIsAuthenticated, token, accounts } = this.state;

        let options = [];
        if(userGroup !== 'Client') {
            // show Utopia University option to internal staff
            options = [{
                label: 'Utopia University',
                value: 'A0000000'
            }];
        }

        accounts.forEach(el => {
            let option = { label: `${el.accountName}`, value: el.accountID };
            if (userGroup === 'Client') {
                if(el.accountID !== 'A0000000') options.push(option);
            } else {
                option = { label: `${el.accountName} (${el.accountID})`, value: el.accountID };
                if(el.accountID !== 'A0000000') options.push(option);
            }
        });

        if (thisIsAuthenticated) {
            if(authRedirect !== '') {
                return (
                    <Redirect to={authRedirect} />
                )
            }
            return (
                <Redirect to="/app/home" />
            )
        }

        return (
            <Formik
                enableReinitialize
                initialValues={{ 
                    accountSelector: '',
                    hiddenAccountSelector: this.state.selectedAccount
                }}
                validate={values => {

                }}
                onSubmit={async (values) => {
                    await sleep(500);
                    //console.log(values);
                    var accountID = '';
                    var accountName = '';
                    var pgWebsite = '';
                    if (values.hiddenAccountSelector !== 'A0000000') {
                        var accountObj = accounts.filter(({accountID}) => accountID === values.hiddenAccountSelector);
                        accountID = accountObj[0].accountID;
                        accountName = accountObj[0].accountName;
                        pgWebsite = accountObj[0].pgWebsite;
                    } else {
                        accountID = 'A0000000';
                        accountName = 'Utopia University';
                        pgWebsite = 'utopia.planmylegacy.org';
                    }
                    
                    var sendValues = JSON.stringify({
                        domoToken: token,
                        accountID: accountID,
                        accountName: accountName,
                        pgWebsite: pgWebsite
                    }, null, 2);

                    const loginResp = await fetch(`${API_HOST}/api/setaccount`, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        credentials: 'include',
                        body: sendValues
                    });

                    var json = await loginResp.json();

                    if (json.state === 'success') {
                        let webClient = json.web_c === '1' ? true : false;
                        let emailClient = json.email_c === '1' ? true : false;
                        let surveyClient = json.survey_c === '1' ? true : false;
                        let customSurveyClient = json.custom_survey_c === '1' ? true : false;
                        let legacySurveyClient = json.legacy_survey_c === '1' ? true : false;
                        let givingDocsClient = json.giving_docs_c === '1' ? true : false;
                        let platformClient = json.platform_c === '1' ? true : false;

                        sessionStorage.setItem('domoToken', token);
                        sessionStorage.setItem('accountName', accountName);

                        cookies.set('domoToken', token, {path: '/', secure: true, sameSite: 'strict'})
                        cookies.set('accountID', accountID, {path: '/', secure: true, sameSite: 'strict'})
                        cookies.set('accountName', accountName, {path: '/', secure: true, sameSite: 'strict'})
                        cookies.set('pgWebsite', pgWebsite, {path: '/', secure: true, sameSite: 'strict'})
                        cookies.set('webClient', webClient, {path: '/', secure: true, sameSite: 'strict'})
                        cookies.set('emailClient', emailClient, {path: '/', secure: true, sameSite: 'strict'})
                        cookies.set('surveyClient', surveyClient, {path: '/', secure: true, sameSite: 'strict'})
                        cookies.set('customSurveyClient', customSurveyClient, {path: '/', secure: true, sameSite: 'strict'})
                        cookies.set('legacySurveyClient', legacySurveyClient, {path: '/', secure: true, sameSite: 'strict'})
                        cookies.set('givingDocsClient', givingDocsClient, {path: '/', secure: true, sameSite: 'strict'})
                        cookies.set('platformClient', platformClient, {path: '/', secure: true, sameSite: 'strict'})

                        dispatch({ type: 'SET_EMBED_TOKEN', value: token });
                        dispatch({ type: 'SET_ACCOUNT_NAME', value: accountName });
                        dispatch({ type: 'SET_ACCOUNT_ID', value: accountID });
                        dispatch({ type: 'SET_AZURE_LOGIN', value: true });
                        dispatch({ type: 'SET_PG_WEBSITE', value: pgWebsite });
                        dispatch({ type: 'SET_WEB_CLIENT', value: webClient });
                        dispatch({ type: 'SET_EMAIL_CLIENT', value: emailClient });
                        dispatch({ type: 'SET_SURVEY_CLIENT', value: surveyClient });
                        dispatch({ type: 'SET_CUSTOM_SURVEY_CLIENT', value: customSurveyClient });
                        dispatch({ type: 'SET_LEGACY_SURVEY_CLIENT', value: legacySurveyClient });
                        dispatch({ type: 'SET_GIVING_DOCS_CLIENT', value: givingDocsClient });
                        dispatch({ type: 'SET_PLATFORM_CLIENT', value: platformClient });
                        // dispatch({ type: 'SET_ACCOUNT_USERS', value: json.users });
                        // dispatch({ type: 'SET_ACCOUNT_TEAM', value: json.accountTeam });
                        this.setState({
                            thisIsAuthenticated: true,
                        })
                    }
                    await sleep(500);
                }}
            >
                {({ isSubmitting }) => (
                    <Form className="form">
                        <Downshift
                            onChange={(selection) => {
                                //console.log(selection ? `You selected ${selection.label}` : 'Selection Cleared');
                                this.setState({
                                    menuIsOpen: false,
                                    selectedAccount: selection ? selection.value : ''
                                })
                            }}
                            itemToString={(item) => (item ? item.label : '')}
                        >
                            {({
                            getInputProps,
                            getItemProps,
                            getMenuProps,
                            getLabelProps,
                            getToggleButtonProps,
                            inputValue,
                            highlightedIndex,
                            isOpen,
                            selectedItem,
                            clearSelection,
                            }) => (
                            <div id="account-selector" className="combobox">
                                <label {...getLabelProps()}>Select Account</label>
                                <div className="combobox__input-wrapper">
                                    <input name="accountSelector" {...getInputProps({
                                        placeholder: 'Enter account name or ID'
                                    })} />
                                    {selectedItem ? (
                                        <button onClick={clearSelection} aria-label="clear selection">
                                            <XIcon />
                                        </button>
                                        ) : (
                                        <button aria-label="toggle menu" aria-haspopup="true" data-toggle="true" {...getToggleButtonProps()}>
                                            <ArrowIcon isOpen={isOpen} />
                                        </button>
                                        
                                    )}
                                </div>
                                <ul className="combobox__options" {...getMenuProps()} >
                                {isOpen &&
                                    options
                                    .filter((item) => !inputValue || (inputValue.length > 1 && item.label.toLowerCase().includes(inputValue.toLowerCase())))
                                    .map((item, index) => (
                                        <li
                                        {...getItemProps({
                                            key: `${item.value}${index}`,
                                            item,
                                            index,
                                            style: {
                                            backgroundColor:
                                                highlightedIndex === index ? '#e2e4e7' : '',
                                            fontWeight: selectedItem === item ? 'bold' : 'normal',
                                            },
                                        })}
                                        >
                                        {item.label}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            )}
                        </Downshift>
                        <input type="hidden" name="hiddenAccountSelector" value={this.state.selectedAccount}/>
                        <button className="btn btn-primary account__btn account__btn--small" type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        );
        
    }
}

export default AccountSelect;
