import React, { PureComponent } from 'react';
//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class TopbarMenuLinks extends PureComponent {
  // static propTypes = {
  //   title: PropTypes.string.isRequired,
  //   icon: PropTypes.string.isRequired,
  //   path: PropTypes.string.isRequired,
  // };

  render() {
    const { title, icon, path, prevPathOverride } = this.props;

    let prevPathname = typeof prevPathOverride !== 'undefined' ? prevPathOverride : window.location.pathname;
    
    return (
      <Link className="topbar__link" to={{
        pathname: path, 
        state: { prevPath: prevPathname }
        }}>
        <span className={`topbar__link-icon lnr lnr-${icon}`} />
        <p className="topbar__link-title">{title}</p>
      </Link>
    );
  }
}
