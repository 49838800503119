import React, { PureComponent } from 'react';
import AccountSelectForm from './components/AccountSelectForm';
import { store } from '../App/store';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class AccountSelect extends PureComponent {
  static contextType = store;

  saveData = (key, value, store_key) => {
    if(typeof(value) === "object") {
      sessionStorage.setItem(key,JSON.stringify(value));

      cookies.set(key, JSON.stringify(value), {path: '/', secure: true, sameSite: 'strict'});
    } else {
      sessionStorage.setItem(key, value);
      cookies.set(key, value, {path: '/', secure: true, sameSite: 'strict'});
    }

    if (store_key) {
      const { dispatch } = this.context;
      dispatch({type: store_key, value: value});
    }
  };

  componentDidMount = () => {
    this.saveData('multipleAccounts', true, 'SET_MULTIPLE_ACCOUNTS');
  }

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <span className="login__logo"><span className="sr-only">Stelter Logo</span></span>
            <AccountSelectForm />
          </div>
        </div>
      </div>
    )
  }
}

export default AccountSelect;
