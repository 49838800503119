import React, { PureComponent } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import sanitizeHtml from 'sanitize-html';
import API_HOST from '../../../../utils/ApiHost';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

class Content extends PureComponent {
  constructor(){
    super();
    this.state = {
      loading: false,
      formStatus: 'incomplete'
    }
  }

  componentDidMount = () => {
    this.setState({loading:true});

  }

  render(){
    const { formStatus } = this.state;

    if(formStatus === 'incomplete') {
      return (
        <Card className="top-margin">
            <CardBody>
                <Formik
              initialValues={{
                email: '', 
                requestType: 'default',
                subject: '',
                page: '',
                comments: ''
              }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
              onSubmit={async (values) => {
                  await sleep(500);
                  values.subject = sanitizeHtml(values.subject);
                  values.page = sanitizeHtml(values.page);
                  values.comments = sanitizeHtml(values.comments);
                  var sendValues = JSON.stringify(values, null, 2);
                  const supportResp = await fetch(`${API_HOST}/api/submitTicket`, {
                    method: 'post',
                    headers: {
                      'Content-Type': 'application/json',
                      'accessToken': sessionStorage.getItem('domoToken')
                    },
                    credentials: 'include',
                    body: sendValues
                  });

                  var json = await supportResp.json();

                  if (json.state === 'success' && json.updateStatus === 204) {
                    // success message
                    this.setState({formStatus: 'success'})
                  } else {
                    this.setState({formStatus: 'error'})
                  }
                  await sleep(500);
              }}
            >
              {({ isSubmitting }) => (
                <Form id="support-form" className={"form form-" + formStatus}>
                  <legend className="sr-only">Support/Feedback Request</legend>
                  <div className="form__form-group">
                  <label htmlFor="email" className="form__form-group-label form-field__required">Email Address</label>
                    <div className="form__form-group-field">
                      <Field name="email" type="email" required />
                      {/* <ErrorMessage name="email" component="div" /> */}
                    </div>
                  </div>

                  <div className="form__form-group">
                  <label className="form__form-group-label form-field__required" htmlFor="requestType">Request Type</label>
                    <div className="form__form-group-field form__form-group-select">
                        <Field
                          as="select"
                          id="requestType"
                          name="requestType"
                          multiple={false}
                          className="Select-control"
                          required
                        >
                          <option value="default" disabled>Select one...</option>
                          <option value="Issue">Report Issue</option>
                          <option value="Feature">Feature Request</option>
                          <option value="Feedback">General Feedback</option>
                          <option value="Help">Help me, I'm lost!</option>
                          <option value="Other">Other</option>
                        </Field>
                    </div>
                  </div>

                  <div className="form__form-group">
                    <label htmlFor="subject" className="form__form-group-label form-field__required">Subject</label>
                    <div className="form__form-group-field">
                      <Field name="subject" type="text" required />
                      {/* <ErrorMessage name="subject" component="div" /> */}
                    </div>
                  </div>

                  {/* <div className="form__form-group">
                    <label htmlFor="screenshot" className="form__form-group-label">Upload screenshot (Optional) [In Progress]</label>
                    <div className="form__form-group-field">
                      <Field name="screenshot" type="file" />
                      <ErrorMessage name="screenshotError" component="div" />
                    </div>
                  </div> */}

                  <div className="form__form-group">
                    <label htmlFor="page" className="form__form-group-label form-field__required">Page (Optional)</label><br />
                    <small>If your issue pertains to a specific page, please include the name of the page. </small>
                    <div className="form__form-group-field">
                      <Field name="page" type="text" />
                      {/* <ErrorMessage name="page" component="div" /> */}
                    </div>
                  </div>

                  <div className="form__form-group">
                    <label htmlFor="comments" className="form__form-group-label form-field__required">Comments</label><br />
                    <small>Please provide details about the issue or request. </small>
                    <div className="form__form-group-field">
                      <Field as="textarea" name="comments" required/>
                      {/* <ErrorMessage name="comments" component="div" /> */}
                    </div>
                  </div>

                  <div className={"submit-wrapper submitting-" + isSubmitting}>
                  <button className="btn btn-primary btn-inline" type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                  {isSubmitting &&
                      (
                        <span class="form-loading-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="46px" height="46px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" aria-hidden="true" tabindex="-1">
                            <circle cx="50" cy="50" fill="none" stroke="#e45525" stroke-width="14" r="36" stroke-dasharray="169.64600329384882 58.548667764616276">
                              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                            </circle>
                          </svg>
                        </span>
                      )
                    }
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      )
    } else if (formStatus === 'success') {
      return (
          <Card className="top-margin">
            <CardBody>
              <h4>Thank you!</h4>
              <p>Your issue has been logged. We will follow up with you about any updates and questions we may have about your request.</p>
            </CardBody>
          </Card>
      )
    } else {
      return (
          <Card className="top-margin">
            <CardBody>
              <h4>Oops! There was an issue with the form.</h4>
              <p>Please <a href="mailto:productsupport@stelter.com" target="_blank" rel="noopener noreferrer">email us</a> directly to report your issue.</p>
            </CardBody>
          </Card>
      )
    }
    
}}
  
export default Content;
