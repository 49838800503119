import React from 'react';

const LoadingIcon = () => {
  return (
    <div className="loading-wrapper">
      <div className="loading-icon"></div>
    </div>
  )
}

export default LoadingIcon;