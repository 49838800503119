import React, { PureComponent } from 'react';
import { Card, Col } from 'reactstrap';
//import API_HOST from '../../../../utils/ApiHost';

class Content extends PureComponent {
  constructor(){
    super();
    this.state = {
      loading: false
    }
  }

  render(){
    return (
      <Col md={12}>
        <Card>
            <div>Proofs content... this will show current and recent proofs with the current user's email address assigned as a reviewer. It will show the details, plus the link out to Ziflow to review.</div>
        </Card>
      </Col>
    )
}}
  
  export default Content;
