import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { store } from './store.js';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import Login from '../Login/index';
import Logout from '../Logout/index';
import AuthReturn from '../AuthReturn/index';
import AccountSelect from '../AccountSelect/index';
import HomePage from '../Pages/Home/index';
import HomeDevPage from '../Pages/Home-Dev/index';
import LeadsOldPage from '../Pages/Leads-Old/index';
import LeadsDevPage from '../Pages/Leads-Dev/index';
import LeadsPage from '../Pages/Leads/index';
import WebPage from '../Pages/Web/Overview/index';
import WebAudiencePage from '../Pages/Web/Audience/index';
import WebFormsPage from '../Pages/Web/Forms/index';
import EmailPage from '../Pages/Email/index';
import EmailDevPage from '../Pages/Email-Dev/index';
import SurveyPage from '../Pages/Survey/index';
import SurveyCustomPage from '../Pages/SurveyCustom/index';
import SurveyDevPage from '../Pages/SurveyDev/index';
import SurveyRequestsPage from '../Pages/SurveyRequests/index';
import SurveyCustomRequestsPage from '../Pages/SurveyCustomRequests/index';
import SurveyRequestsDevPage from '../Pages/SurveyRequestsDev/index';
import LegacySurveyPage from '../Pages/SurveyLegacy/index';
import DirectMailPage from '../Pages/DirectMail/index';
import GivingDocsPage from '../Pages/GivingDocs/index';
import PlatformPage from '../Pages/PerformancePlatform/index';
import FilesPage from '../Pages/Files/index';
import ProofsPage from '../Pages/Proofs/index';
import Glossary from '../Pages/Glossary/index';
import Support from '../Pages/Support/index';
import ReleaseNotes from '../Pages/ReleaseNotes/index';
const cookies = new Cookies();

const titleCase = (str) => {
  return str.toLowerCase().split(' ').map(function(word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

const Pages = () => (
  <Switch>
    <Route path="/app/home-dev" component={HomeDevPage} />
    <Route path="/app/home" component={HomePage} />
    <Route path="/app/leads-old" component={LeadsOldPage} />
    <Route path="/app/leads-dev" component={LeadsDevPage} />
    <Route path="/app/leads" component={LeadsPage} />
    <Route path="/app/web" component={WebPage} />
    <Route path="/app/web-audience" component={WebAudiencePage} />
    <Route path="/app/web-forms" component={WebFormsPage} />
    <Route path="/app/email-dev" component={EmailDevPage} />
    <Route path="/app/email" component={EmailPage} />
    <Route path="/app/survey-dev" component={SurveyDevPage} />
    <Route path="/app/survey" component={SurveyPage} />
    <Route path="/app/survey-custom" component={SurveyCustomPage} />
    <Route path="/app/survey-requests-dev" component={SurveyRequestsDevPage} />
    <Route path="/app/survey-requests" component={SurveyRequestsPage} />
    <Route path="/app/survey-custom-requests" component={SurveyCustomRequestsPage} />
    <Route path="/app/legacy-survey" component={LegacySurveyPage} />
    <Route path="/app/direct-mail" component={DirectMailPage} />
    <Route path="/app/proofs" component={ProofsPage} />
    <Route path="/app/giving-docs" component={GivingDocsPage} />
    <Route path="/app/performance-platform" component={PlatformPage} />
    <Route path="/app/files" component={FilesPage} />
    <Route path="/app/support/glossary" component={Glossary} />
    <Route path="/app/support/request" render={(props) => <Support {...props} title={`Support Request`}/>} />
    <Route path="/app/support/videos" render={(props) => <Support {...props} title={`How-to Videos`}/>} />
    <Route path="/app/support/release-notes" component={ReleaseNotes} />
    <Route path="/app/support" render={(props) => <Support {...props} title={`Support Request`}/>} />
  </Switch>
);

const WrappedRoutes = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { accountID, userGroup, userE } = globalState.state;
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (typeof cookies.get('authRedirect') !== 'undefined' && (cookies.get('authRedirect').includes('clearcache') || cookies.get('authRedirect').includes('logout'))) {
      cookies.remove('authRedirect', {path: '/', secure: true})
    }
    dispatch({ type: 'SET_AUTH_REDIRECT', value: '' });

    var path = location.pathname.indexOf('/app/') > -1 ? location.pathname.split('/app/')[1] : location.pathname.substring(1);
        path = path.replace(/\//g,' ');
    var page = titleCase(path);

    var dataAccountID = accountID !== '' && userGroup === 'Client' ? accountID : 'Stelter';

    var userEmail = atob(userE);
    // need to unhash on the Domo side
    var userEmailHash = userEmail.replace('@','%7');
        userEmailHash = userEmailHash.replace(/\./g,'%6');
        userEmailHash = userEmailHash.replace(/a/g,'%1');
        userEmailHash = userEmailHash.replace(/e/g,'%2');
        userEmailHash = userEmailHash.replace(/i/g,'%3');
        userEmailHash = userEmailHash.replace(/o/g,'%4');
        userEmailHash = userEmailHash.replace(/u/g,'%5');

    if(history.action === 'PUSH') {
      window.stlDigitalData = {
        internalExternal: "internal",
        page: {
            pageInfo: {
                pageName: window.orgId + ":" + page,
                pageType: "Intelligence Center"
            },
            prefix: {
                sitePrefix: window.orgId
            },
            section: {
                section1: "Intelligence Center",
                section2: dataAccountID,
                section3: userEmailHash
            }
        }
      }

      window._satellite.track("pageview", window.stlDigitalData);
      
    } else {
      if(typeof window.stlDigitalData === 'undefined') {
        window.stlDigitalData = {
          internalExternal: "internal",
          page: {
              pageInfo: {
                  pageName: window.orgId + ":" + page,
                  pageType: "Intelligence Center"
              },
              prefix: {
                  sitePrefix: window.orgId
              },
              section: {
                  section1: "Intelligence Center",
                  section2: dataAccountID,
                  section3: userEmailHash
              }
          }
        }
      }
      
      if(document.getElementById("launch-script") === null){
        var h = process.env.PUBLIC_URL,
            n = (h.indexOf("intelligence.stelter.com") > -1 && h.indexOf("staging") === -1) ? "EN81c1e8fed72e4ecebe01da3d728a0cbb" : "EN2e6dc75c567143c9aff512c4906ee798-development";
      
        var c = document.createElement('script'); 
            c.id = "launch-script"; 
            c.src = "https://assets.adobedtm.com/launch-" + n + ".min.js"; 
            c.async = 1;
        document.head.appendChild(c)
      }
    }
    
  }, [dispatch, location, history, accountID, userGroup, userE]);  

  return (
    <div className="main-wrapper">
      <Layout />
      <Route path="/app" component={Pages} />
    </div>
  )
};

const Router = () => {
  const globalState = useContext(store);
  const { isAuthenticated } = globalState.state;
  const location = useLocation();
  const cookieAlive = typeof cookies.get('active') !== 'undefined';
  const pageClass = location.pathname ? location.pathname.substring(location.pathname.lastIndexOf('/') + 1) + '-page': '';

  return (
    <MainWrapper> 
      <main className={pageClass}>
        {isAuthenticated && cookieAlive ? (
          <Switch>
            <Route exact path="/" render={() => {
                return (
                  <Redirect to="/app/home" />
                )
              }}
            />
            <Route exact path="/login" render={() => {
                return (
                  <Redirect to="/app/home" />
                )
              }}
            />
            <Route exact path="/error" render={() => {
                return (
                  <Redirect to="/app/home" />
                )
              }}
            />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/clearcache" component={Login} />
            <Route exact path="/authreturn" component={AuthReturn} />
            <Route exact path="/account-select" component={AccountSelect} />
            <Route path="/" component={WrappedRoutes} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/authreturn" component={AuthReturn} />
            <Route path="/" render={() => {
                if(location.pathname.length > 1 && !location.pathname.includes("logout") && !location.pathname.includes("login")) {
                  var query = location.search !== '' ? location.search : '';
                  var redirectPath = location.pathname + query;
                  cookies.set('authRedirect', redirectPath, {path: '/', secure: true, sameSite: 'strict'})
                  // dispatch({ type: 'SET_AUTH_REDIRECT', value: redirectPath });
                } else if (location.pathname.length === 0 && !location.pathname.includes("logout") && !location.pathname.includes("login")) {
                  cookies.set('authRedirect', '', {path: '/', secure: true, sameSite: 'strict'})
                  // dispatch({ type: 'SET_AUTH_REDIRECT', value: '' });
                }
                return (
                  <Redirect to="/login" />
                )
              }}
            />
          </Switch>
        )}
      </main>
    </MainWrapper>
  )
  
};

export default Router;
