import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Content from './components/GivingDocsContent';
import PageTooltip from '../../../shared/PageTooltip';

const Page = () => {
  const [refreshTime, setRefreshTime] = useState(0);

  return (
    <div className="container__wrap">
      <Container className="dashboard">
        <PageTooltip state={{ refreshTime: [refreshTime, setRefreshTime] }}/>
        <Row className="row__dashboard-intro">
          <Col md={12} className="dashboard-intro ">
            <h3 className="page-title dashboard-title">Giving Docs</h3>
            <p className="dashboard-subtitle">View the results from Giving Docs to examine your donor engagement.</p>
          </Col>
        </Row>
        <Row>
          <Content key={refreshTime}/>
        </Row>
      </Container>
    </div>
  );
};

export default Page;