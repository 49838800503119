import React from "react";
import { Card, CardBody } from "reactstrap";

export default function BlockTracking({ pgwebsite, usergroup }) {
    return (
        <Card className="top-margin auto-height">
            {usergroup === 'Client' &&
                <CardBody className="card-sm">
                    <p><strong>Block Your Traffic</strong></p>
                    <p>Opt out now to block your personal traffic from being tracked on your planned giving website going forward.</p>
                    <p>
                        {
                        // eslint-disable-next-line
                        }<a className="btn btn-primary" style={{marginTop: '10px'}} href={'http://'+ pgwebsite + '/internal-opt-out'} target="_blank" rel="noopener">Opt Out</a>
                    </p>
                </CardBody>
            }
            {usergroup !== 'Client' &&
                <CardBody className="card-sm">
                    <p><strong>Block Your Traffic</strong></p>
                    <p>Stelter staff: Use the button below to confirm you are blocking your traffic from client planned giving web analytics. You will need to visit this Adobe link in all browsers and devices you use to access client websites and again anytime you clear your browser&rsquo;s cache or cookies.</p>
                    <p>
                        <a className="btn btn-primary" style={{marginTop: '10px'}} href={'https://thesteltercompany.sc.omtrdc.net/optout.html?locale=en_US&optout=1'} target="_blank" rel="noopener noreferrer">Opt Out</a>
                    </p>
                </CardBody>
            }
        </Card>
    )

}