import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { checkDataStatus } from "../../../../utils/helpers";
import CheckDecagram from 'mdi-react/CheckDecagramIcon';

export default function DomoStatus() {
    const [status, setStatus] = useState();
    const [statusMessage, setStatusMessage] = useState();

    useEffect(() => {
        async function fetchStatus() {
            let statusCheck = await checkDataStatus();
            setStatus(statusCheck.status)
            setStatusMessage(statusCheck.message)
        }
        fetchStatus()
    }, []);

    return (
        <Card className="top-margin auto-height">
            <CardBody className="card-sm">
                <p><strong>Server Status</strong></p>
                <p>
                    {status !== 'success' ? (null) : 
                        <CheckDecagram className="success-icon" />
                    }{ statusMessage }
                </p>
            </CardBody>
        </Card>
    )

}