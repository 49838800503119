import React, { PureComponent, Fragment } from 'react';
import { store } from '../../App/store';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

export default class TopbarProfile extends PureComponent {
  static contextType = store;

  constructor() {
    super();
    this.state = {
      collapse: false,
      logout: false,
      user: '',
      userGroup: '',
      accountName: '',
      multipleAccounts: false,
      demo: false
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  componentDidMount = () => {
    const globalState = this.context;
    const { user, userGroup, accountName, multipleAccounts } = globalState.state;
    const isDemo = accountName === 'Utopia University';
    this.setState({user: user, userGroup: userGroup, accountName: accountName, multipleAccounts: multipleAccounts, demo: isDemo})
  }

  render() {
    const { collapse, user, userGroup, accountName, multipleAccounts, demo } = this.state;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          {/* <img className="topbar__avatar-img" src={Ava} alt="avatar" /> */}
          <p className="topbar__avatar-name">{accountName}&nbsp;&nbsp;&bull;&nbsp;&nbsp;{user}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {(multipleAccounts || demo) && 
              <Fragment>
                <TopbarMenuLink title="Account Select" icon="select" path="/account-select" />
                <div className="topbar__menu-divider" />
              </Fragment>
            }
            <TopbarMenuLink title="Support" icon="bubble" path="/app/support" />
            <div className="topbar__menu-divider" />
            {(userGroup === 'Admin' || demo) && 
              <Fragment>
                <TopbarMenuLink title="Clear Cache" icon="trash" path="/clearcache" prevPathOverride="/clearcache"/>
                <div className="topbar__menu-divider" />
              </Fragment>
            }
            <TopbarMenuLink title="Log Out" icon="exit" path="/logout" prevPathOverride="/logout" onClick={this.handleSignoutPress}/>
          </div>
        </Collapse>
      </div>
    );
  }
}
