import React, { PureComponent } from 'react';
import { store } from '../../../App/store';
import { Card, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import LoadingIcon from '../../../../shared/LoadingIcon';
import { getData, aggByKey, arrsToJson } from '../../../../utils/helpers';
// import 'chart.css';

// Returns an array of dates between the two dates
const getDates = (startDate, endDate) => {
  const dates = []
  let currentDate = startDate
  const addDays = function (days) {
    const date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }
  while (currentDate <= endDate) {
    dates.push(currentDate)
    currentDate = addDays.call(currentDate, 1)
  }
  return dates
}

class Content extends PureComponent {
  static contextType = store;

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      iframeHeight: '100%',
      containerHeight: 'calc(100vh - 150px)',
      stelterTeamData: '',
      myTeamData: '',
      mostVisits: '',
      webData: '',
      formData:'',
      totalForms: '',
      mostForms: '',
      groupedFormData: '',
      ninetyDayGroupedFormData: '',
      commentFormData: '',
      showFormsModal: false,
      leadsData: '',
      leadsChartData: '',
      topLeadsData: '',
      mostLeads: '',
      inviteLinkCopied: '',
      loadedClass: ''
    }
    this.containerRef = React.createRef();
  }

  copyLink = (url) => {
		var input = document.createElement('input');
		input.type = 'text';
		input.className = 'hidden-input';
		input.value = url;
		document.body.appendChild(input);
		input.select()
		document.execCommand('copy');
		document.body.removeChild(input);

		this.setState({inviteLinkCopied: url})
		setTimeout(() => {
			this.setState({inviteLinkCopied: ''})
		}, 1000);
		
	}

  toggleFormsModal = () => this.setState({showFormsModal: !this.state.showFormsModal});

  componentDidMount = async () => {
    const globalState = this.context;
    const { userGroup, accountID } = globalState.state;
    const position = this.containerRef.current.getBoundingClientRect();
    const topPosition = position.top;
    const isAdmin = userGroup === 'Admin';
    this.setState({
      loading: true,
      containerHeight: `calc(100vh - ${topPosition}px)`
    });

    //this.fetchEmbed = fetchEmbed.bind(this);
    this.getData = getData.bind(this);
    this.aggByKey = aggByKey.bind(this);
    this.arrsToJson = arrsToJson.bind(this);
    //srcDoc = await this.fetchEmbed(embedItem, '', isAdmin);
    // get unique web visitors by day
    //let webData = await this.getData(accountID, isAdmin, '5e3896ac-753b-47c0-8e8b-4da8484bcb90',"SELECT `Date`, COUNT(DISTINCT `visit_instance`) AS VisitCount FROM table WHERE `Account ID` = '" + accountID + "' WHERE `Date` between DATEADD(DAY,-30,GETDATE()) and  GETDATE() GROUP BY `Date` ORDER BY `Date`")
    var ninetyDaysAgo = new Date(new Date().setDate((new Date()).getDate() - 90)).toISOString().split('T')[0];

    let stelterTeamDataResponse = await this.getData(accountID, isAdmin,'0f6bf2a8-d602-423f-8969-dea1fbe93c88', "SELECT `User.Name`,`Email`,`Phone`,`Team Member Role` FROM table WHERE `Account ID` = '" + accountID + "' ORDER BY `Team Member Role`")

    let myTeamDataResponse = await this.getData(accountID, isAdmin,'2b550f17-6c62-4ae6-93d3-25edf00c72ce', "SELECT `Name`,`Email`,`Last Activity`,`Status`,`InviteRedeemUrl` FROM table WHERE `Account ID` = '" + accountID + "' AND `Enabled` = 'True' AND `Status__c` = 'Active' AND `Analytics__c` = 'true' AND `Email` NOT LIKE '%stelter.com%' AND `Email` NOT LIKE '%butzke%' AND `Email` NOT LIKE '%perficient%' ORDER BY `Last Activity for Sort` DESC")

    let webDataResponse = await this.getData(accountID, isAdmin, '5e3896ac-753b-47c0-8e8b-4da8484bcb90',"SELECT `Date`, COUNT(DISTINCT `visit_instance`) AS visitcount FROM table WHERE `Account ID` = '" + accountID + "' AND `Date` >= '"+ ninetyDaysAgo + "' GROUP BY `Date` ORDER BY `Date`")

    let formDataResponse = await this.getData(accountID, isAdmin, '35c40c9f-c16e-45f6-bd62-2272c5686885',"SELECT `Date`,`DateTime`,`Row ID`,`Full Name`,`EmailAddress`,`Comments` FROM table WHERE `Account ID` = '" + accountID + "' AND `Date` >= '"+ ninetyDaysAgo + "' ORDER BY `Date`")

    let leadsDataResponse = await this.getData(accountID, isAdmin,'5b156e0a-6abf-47c2-b7fb-d75b5a52bf62', "SELECT `Full Name`,`EmailAddress`,`TEST Score Last 90 Days`,`TEST Lead Score Group 90 Days` FROM table WHERE `Account ID` = '" + accountID + "' AND `TEST Lead Score Group 90 Days` != 'No Score' ORDER BY `TEST Score Last 90 Days` DESC")

    let stelterTeamData = arrsToJson(stelterTeamDataResponse.rows, stelterTeamDataResponse.columns)
    console.log(stelterTeamData)

    let myTeamData = arrsToJson(myTeamDataResponse.rows, myTeamDataResponse.columns)
    myTeamData = myTeamData.map(el => {
      var lastActivity = el['Last Activity'];
      var status = el['Status'];
      if (lastActivity !== '') {
        el['Last Activity'] = lastActivity.replace(/CST|CDT/g,'CT')
      } else {
        if(status.indexOf("Pending") > -1) {
          el['Last Activity'] = 'Copy Invitation Link'
        } else {
          el['Last Activity'] = 'Accepted/No Sign-In'
        }
      }
      return el;
    })
    console.log(myTeamData)

    console.log(webDataResponse)
    let visitcountOnly = webDataResponse.rows.map(row => row[1]);
    let mostVisits = Math.max(...visitcountOnly);
    //console.log(mostVisits);
    let webData = arrsToJson(webDataResponse.rows, webDataResponse.columns)
    console.log(webData)

    const totalVisits = visitcountOnly.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    let formData = arrsToJson(formDataResponse.rows, formDataResponse.columns)
    console.log(formData)
    let groupedFormData = aggByKey(formData,'Date');
    let commentFormData = formData.filter(row => row.Comments !== '').map(row => {
      var newRow = {...row};
      newRow["DateTimeFormatted"] = (new Date(newRow["DateTime"])).toLocaleString('en-us','America/Chicago').replace(/[,]/g,'');
      return newRow;
    })
    commentFormData = commentFormData.reverse();

    let formcountOnly = groupedFormData.map(row => row.Count);
    let totalForms = formcountOnly.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    let mostForms = Math.max(...formcountOnly);

    // return only form submissions from last 90 days but excluding first batch and last batch of dates that had zero submissions
    let today = new Date();
    let ninetyDaysAgoJs = new Date(new Date().setDate(today.getDate() - 90));
    let all90Dates = getDates(ninetyDaysAgoJs, today).map(date => date.toISOString().split('T')[0]);
    let ninetyDayGroupedFormData = all90Dates.map(date => {
      var formObj = {};
      formObj.Date = date;
      if(typeof groupedFormData.find(el => el.Date === date) !== 'undefined') {
        formObj.Count = groupedFormData.find(el => el.Date === date).Count
      } else {
        formObj.Count = 0;
      }
      return formObj;
    });
    let reversedNinetyDayGroupedFormData = [...ninetyDayGroupedFormData].reverse();
    let firstFormSubmissionIndex = ninetyDayGroupedFormData.findIndex(el => el.Count !== 0) > -1 ? ninetyDayGroupedFormData.findIndex(el => el.Count !== 0) : 0;
    console.log('firstFormSubmissionIndex: ' + firstFormSubmissionIndex)
    let lastFormSubmissionIndex = reversedNinetyDayGroupedFormData.findIndex(el => el.Count !== 0) > -1 ? ninetyDayGroupedFormData.length - reversedNinetyDayGroupedFormData.findIndex(el => el.Count !== 0) : ninetyDayGroupedFormData.length;
    console.log('lastFormSubmissionIndex: ' + lastFormSubmissionIndex)
    let trimmedNinetyDayGroupedFormData = ninetyDayGroupedFormData.slice(firstFormSubmissionIndex,lastFormSubmissionIndex);

    console.log(groupedFormData)
    console.log(commentFormData)

    


    let leadsData = arrsToJson(leadsDataResponse.rows,leadsDataResponse.columns)
    console.log(leadsData)

    let topLeadsData = leadsData.filter(row => row["TEST Lead Score Group 90 Days"] === 'Hot').length > 5 ? leadsData.filter((row,index) => index < 5) : leadsData.filter(row => row["TEST Lead Score Group 90 Days"] === 'Hot');

    topLeadsData = topLeadsData.map(row => {
      var newRow = {...row};
      newRow['NameEmail'] = row['Full Name'] + ' ' + row['EmailAddress'];
      return newRow;
    })

    let leadsChartData = [
      {'Type': 'Lead', 'Group': 'Hot','Count': leadsData.filter(row => row["TEST Lead Score Group 90 Days"] === 'Hot').length},
      {'Type': 'Lead', 'Group': 'Warm','Count': leadsData.filter(row => row["TEST Lead Score Group 90 Days"] === 'Warm').length},
      {'Type': 'Lead', 'Group': 'Cool','Count': leadsData.filter(row => row["TEST Lead Score Group 90 Days"] === 'Cool').length}
    ];
    
    let leadscountOnly = leadsChartData.map(row => row.Count);
    let mostLeads = Math.max(...leadscountOnly);

    console.log(topLeadsData)
    console.log(leadsChartData)
  
    //console.log(stelterTeamData)
    //console.log(myTeamData)

    this.setState({ // prevState - previous state 
      loading: false, 
      loaded: true,
      stelterTeamData: stelterTeamData,
      myTeamData: myTeamData,
      webData: webData,
      mostVisits: mostVisits,
      totalVisits: totalVisits,
      formData: formData,
      totalForms: totalForms,
      mostForms: mostForms,
      groupedFormData: groupedFormData,
      ninetyDayGroupedFormData: trimmedNinetyDayGroupedFormData,
      commentFormData: commentFormData,
      leadsData: leadsData,
      leadsChartData: leadsChartData,
      topLeadsData: topLeadsData,
      mostLeads: mostLeads
    });
  }

  render() {
		let { stelterTeamData, myTeamData, webData, mostVisits, totalVisits, formData, groupedFormData, ninetyDayGroupedFormData, mostForms, totalForms, commentFormData, showFormsModal, leadsData, leadsChartData, topLeadsData, mostLeads, showChart, showMyTeam, inviteLinkCopied, loading, loaded} = this.state;

    
		return (
      <Col md={12}>
        <Card>
          {loading ? (
            <LoadingIcon />
            ) : (
              <div className={"App wrapper loaded-" + loaded} ref={this.containerRef}>
                <div className="app-container">
                <Row className="non-iframe-container">
                  <Col md={9} className="flex-container flex-row">
                    {/* <div ref={div => (this.formChartDiv = div)} className="mychart" /> */}
                    <div id="form-submissions" className="one card home-chart-card card__form-submissions">
                      <header><span className="header-value">{totalForms}</span> Form Submissions</header>
                      <table id="form-submissions-table" className="chart-table charts-css column data-spacing-1 hide-labels hide-data">
                        <thead>
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ninetyDayGroupedFormData !== '' &&
                            ninetyDayGroupedFormData.map((row, index) => {
                              return (
                                <tr key={row.Date + '_' + index}>
                                  <th scope="row">{row.Date}</th>
                                  <td style={{"--size": (row.Count / mostForms)}}>
                                    <span className="data">{row.Count}</span> 
                                    <span className="tooltip"> {row.Date}: {row.Count} submissions</span>
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                    {/* <div ref={div => (this.webChartDiv = div)} className="mychart" /> */}
                    <div id="web-visits" className="two card home-chart-card card__web-visits">
                      <header><span className="header-value">{totalVisits}</span> Web Visits</header>
                      <table id="web-visits-table" className="chart-table charts-css column data-spacing-1 hide-labels hide-data">
                          <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Visits</th>
                            </tr>
                          </thead>
                          <tbody>
                            {webData !== '' &&
                              webData.map((row, index) => {
                                return (
                                  <tr key={row.Date + '_' + index}>
                                    <th scope="row">{row.Date}</th>
                                    <td style={{"--size": (row.visitcount / mostVisits)}}>
                                      <span className="data">{row.visitcount}</span> 
                                      <span className="tooltip"> {row.Date}: {row.visitcount} visits</span>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                    </div>
                    <div id="comments" className="three card home-chart-card card__comments">
                      {/* <div ref={div => (this.formCommentsDiv = div)} className="mychart" /> */}
                      <div className="card-container">
                        <header><span className="header-value">{commentFormData.length} Contact Form Submissions</span></header>
                        <p><button id="form-popup-btn" onClick={() => this.toggleFormsModal()}>View Comments</button></p>
                      </div>
                      <Modal 
                        isOpen={showFormsModal} 
                        target='form-popup-btn' 
                        className='forms-modal'
                        scrollable={true}
                        toggle={() => this.toggleFormsModal()}>
                        <ModalHeader toggle={() => this.toggleFormsModal()}>Contact Forms</ModalHeader>
                        <ModalBody>
                        <table className="comments-table">
                          <thead>
                            <tr>
                              <th><span className="th-inner">Name</span></th>
                              <th><span className="th-inner">Email Address</span></th>
                              <th><span className="th-inner">Date Time</span></th>
                              <th><span className="th-inner">Comments</span></th>
                            </tr>
                          </thead>
                          <tbody>
                            {commentFormData !== '' &&
                              commentFormData.map((row, index) => {
                                return (
                                  <tr key={row.DateTime + '_' + index}>
                                    <td className="cell-align-left" style={{minWidth: "150px"}}>{row["Full Name"]}</td>
                                    <td className="cell-align-left" style={{minWidth: "200px"}}>{row["EmailAddress"]}</td>
                                    <td className="cell-align-left">{row["DateTimeFormatted"]}</td>
                                    <td className="cell-align-left">{row["Comments"]}</td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                        </ModalBody>
                      </Modal>
                    </div>
                    
                    <div id="leads-summary" className="five card home-chart-card card__leads-summary">
                      {/* <div ref={div => (this.leadsChartDiv = div)} className="mychart" /> */}
                      <header><span className="header-value">{leadsData.length}</span> Leads</header>
                      <table id="leads-table" className="chart-table charts-css column data-spacing-10 show-labels">
                        <thead>
                          <tr>
                            <th scope="col">Group</th>
                            <th scope="col">Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {leadsChartData !== '' &&
                            leadsChartData.map((row, index) => {
                              return (
                                <tr key={row.Group + '_' + index}>
                                  <th scope="row">{row.Group}</th>
                                  <td style={{"--size": (row.Count / mostLeads)}}>
                                    <span className="data">{row.Count}</span> 
                                    <span className="tooltip"> {row.Group} Leads: {row.Count}</span>
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                    <div id="top-leads" className="six card home-chart-card card__top-leads">
                      {/* <div ref={div => (this.topLeadsChartDiv = div)} className="mychart" /> */}
                    </div>
                    
                  </Col>
                  <Col md={3} className="flex-container flex-col team-card-container">
                    <div id="stelter-team" className="four card team-card card__stelter-team">
                      <header className="app-header">My Stelter Team</header>       
                      <div className="table-container">
                        <table className="response-table">
                          <thead>
                            <tr>
                              <th><span className="th-inner">My Stelter Team</span></th>
                            </tr>
                          </thead>
                          <tbody>
                            {stelterTeamData !== '' &&
                              stelterTeamData.map((row, index) => {
                                return (
                                  <tr key={row.Name + '_' + index}>
                                    <td className="cell-align-left">
                                      <span className="name-row">{row["User.Name"]} {row["Team Member Role"]}</span><br />
                                      <span className="contactinfo-row">{row.Phone} &bull; <a href={"mailto:"+ row.Email} target="_blank" rel="noopener noreferrer">{row.Email}</a></span>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="my-team" className="seven card team-card card__my-team">
                      <header className="app-header">My Team</header>       
                      <div className="table-container">
                        <table className="response-table">
                          <thead>
                            <tr>
                              <th><span className="th-inner">Name</span></th>
                              <th><span className="th-inner">Last Activity</span></th>
                            </tr>
                          </thead>
                          <tbody>
                          {myTeamData !== '' &&
                            myTeamData.map((row, index) => {
                              return (
                                <tr key={row.Name + '_' + index}>
                                  <td className="cell-align-left">
                                    <span className="name-row">{row.Name}</span><br />
                                    <span><a href={"mailto:"+ row.Email} target="_blank" rel="noopener noreferrer">{row.Email}</a></span>
                                  </td>
                                  {row['Last Activity'].indexOf('Invitation') === -1 &&
                                    <td className="cell-align-left">{row['Last Activity']}</td>
                                  }
                                  {row['Last Activity'].indexOf('Invitation') > -1 &&
                                    <td className="cell-align-left">
                                      <span className={inviteLinkCopied === row.InviteRedeemUrl ? 'invite-link-btn copied' : 'invite-link-btn'} onClick={() => {
                                        this.copyLink(row.InviteRedeemUrl)
                                        }}>
                                        {row['Last Activity']}
                                      </span>
                                    </td>
                                  }
                                </tr>
                              );
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                </Row>
                </div>
              </div>
            )
          }
        </Card>
      </Col>
		);
	}
}

export default Content;
