import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { store } from '../App/store';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import IdleTimer from 'react-idle-timer';
import { IdleModal } from '../../shared/IdleModal';
import { throttle } from '../../utils/helpers';
import API_HOST from '../../utils/ApiHost';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

//eslint-disable-next-line
import Worker from "worker-loader!../../leadsWorker.js";
const worker = new Worker();

class Layout extends PureComponent {
  static contextType = store;

  constructor(props,context) {
    super(props);
    this.state = {
      width: 0,
      sidebarShow: false,
      sidebarCollapse: false,
      showModal: false,
      timeout: 1000 * 60 * 60, // 60 minute idle logout
      warningTimeout: 60, // 1 minute warning popup
      loadTime: Date.now()
    };

    //sicSurveyPopup: 'menu',
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    //this.hideSurveyModal = this.hideSurveyModal.bind(this)
    //this.handleLogout = this.handleLogout.bind(this)
  }

  changeSidebarVisibility = () => {
    const { dispatch } = this.context;
    this.setState(prevState => ({ sidebarCollapse: !prevState.sidebarCollapse }), () => {
      cookies.set('sidebarCollapse', this.state.sidebarCollapse, {path: '/', secure: true, sameSite: 'strict'})
      dispatch({ type: 'SET_SIDEBAR_COLLAPSE', value: this.state.sidebarCollapse });
    });
  };

  changeMobileSidebarVisibility = () => {
    this.setState(prevState => ({ sidebarShow: !prevState.sidebarShow }));
  };

  _onAction(e) {
    // console.log('user did something', e)
    this.setState({isTimedOut: false})
  }

  _onActive(e) {
    // console.log('user is active', e)
    this.setState({isTimedOut: false})
  }

  _onIdle(e) {
    // console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
        //this.props.history.push('/logout')
    } else {
      this.setState({showModal: true})
      this.idleTimer.reset();
      this.setState({isTimedOut: true})

      if (window.Notification && Notification.permission === "granted") {
        var notification = new Notification("Are you still there?", {
          icon: `${process.env.PUBLIC_URL}/img/logo_light_p.png`,
          body: 'Click to stay signed into the Stelter Intelligence Center.',
          tag: 'sicAlertNotification' + Date.now()
        });
        notification.onclick = function () {
          window.focus();
          notification.close();
        };
      }
    }
    
  }

  handleClose() {
    this.setState({showModal: false})
  }

  updateDimensions = throttle(() => {
    if(window.innerWidth < 1200) {
      this.setState({ 
        width: window.innerWidth,
        sidebarShow: false,
        sidebarCollapse: true
      });
    } 
  }, 250)

  async componentDidMount () {
    const globalState = this.context;
    const { dispatch } = globalState;
    const { sidebarCollapse, accountID, userGroup, leadsData, timeoutDuration } = globalState.state;
    //const isDev = process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV !== 'prod';
    const isAdmin = userGroup === 'Admin';
    var timerTimeout = userGroup !== 'Client' ? this.state.timeout * 2 : this.state.timeout * Number(timeoutDuration);

    this.setState({
      sidebarCollapse: sidebarCollapse,
      timeout: timerTimeout
    })

    window.addEventListener('resize', this.updateDimensions);

    // old dataset: '332a6d87-72c0-4789-bb6a-4f9786fd2297'
    // dev view: '305e560f-1326-454f-9635-c511cd9c0378'
    // new view: 'acbe1861-0dc0-43ec-931e-e16e460bef2c'
    if(leadsData.length === 0) {
      worker.postMessage({
        eventType: 'queryLeads', 
        host: API_HOST, 
        domoToken: sessionStorage.getItem('domoToken'), 
        accountID: accountID, 
        isAdmin: isAdmin, 
        detailsDataset: 'acbe1861-0dc0-43ec-931e-e16e460bef2c', 
        detailsQuery: "SELECT `EventDate`, `DonorID`, `FullName`, `EmailAddress`, `NameEmail`, `AdditionalEmail`, `EventType`, `EventDetails`, `GiftIntention`, `GiftIntentionSimple`, `ScoreContent`, `URL_Path`, `LeadScoreValue`, `ScoreLast365Days`, `ScoreLast90Days`, `LeadScoreGroup365Days`, `LeadScoreGroup90Days`, `State_Geo_Region`, `ReferringDomain`, `VisitSource`,`CampaignName`,`CampaignInfo`,`LastActivityDate` FROM table WHERE `AccountID` = '" + accountID + "' ORDER BY `RankScore`", 
        summaryDataset: "52d08763-e6f2-48a1-9c77-62f341cad73f", 
        summaryQuery: "SELECT `DonorID`, `FullName`, `EmailAddress`, `NameEmail`, `AdditionalEmail`, `Activities`, `VisitSources`,`GiftIntention`, `GiftIntentionSimple`, `LeadScoreGroup90Days`, `LeadScoreGroup365Days`, `LastActivityDate`, `AdditionalEmail` FROM table WHERE `AccountID` = '" + accountID + "'", 
        activitiesDataset: "5ec678e2-162b-4bd5-a54a-434ca45cf379", 
        activitiesQuery: "SELECT `Activities`, `VisitSources` FROM table WHERE `AccountID` = '" + accountID + "'", 
        pFilters: ""
      });

      worker.addEventListener("message", e => {
        if(isAdmin) {
          console.log("Received response:");
          console.log(e.data);
        }
        
        dispatch({ type: 'SET_LEADS_DATA', value: e.data.leadsData });
        dispatch({ type: 'SET_LEADS_SUMMARY', value: e.data.leadsSummaryData });
        dispatch({ type: 'SET_LEADS_NAMEEMAILS', value: e.data.leadsNameEmails });
        dispatch({ type: 'SET_LEADS_ACTIVITIES', value: e.data.leadActivities });
        dispatch({ type: 'SET_VISIT_SOURCES', value: e.data.visitSources });
        dispatch({ type: 'SET_TOTAL_LEADS', value: e.data.totalLeads });
      }, false);
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    /*
    const globalState = this.context;
    const { sicSurveyPopup } = globalState.state;
    const { loadTime } = this.state;

    console.log(Date.now() - loadTime);
    if(sicSurveyPopup === 'center' && (Date.now() - loadTime > 1000)) {
      this.setState({
        sicSurveyPopup: 'center'
      })
    } else {
      this.setState({
        sicSurveyPopup: 'menu'
      })
    }
    */
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      window.removeEventListener('resize', this.updateDimensions);
      return;
    };
  }

  // hideSurveyModal = () => {
  //   if(this.context) {
  //     const { dispatch } = this.context;
  
  //     // update cookie, update store
  //     let surveyCookieExpiration = new Date(Date.parse('01 Nov 2023 00:00:00 CDT'));
  //     cookies.set('sicSurveyPopup', 'menu', {path: '/', secure: true, sameSite: 'strict', expires: surveyCookieExpiration})
  //     dispatch({ type: 'SET_SIC_SURVEY_POPUP', value: 'menu' });
  //   }
  // }

  render() {
    const globalState = this.context;
    let { userGroup, sicSurveyUrl } = globalState.state;
    const { sidebarShow, sidebarCollapse, timeout } = this.state;
    const isDev = process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV !== 'prod';

    const isAdmin = userGroup === 'Admin';

    //let showSurveyPopup = false;
    //let showSurveyPopup = (((isDev && isAdmin) || (typeof sicSurveyUrl !== 'undefined' && sicSurveyUrl !== '')) && this.state.sicSurveyPopup === 'center');

    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebarCollapse,
    });

    return [
      <IdleTimer
        key={'IdleTimerKey'}
        ref={ref => { this.idleTimer = ref }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        onAction={this.onAction}
        debounce={250}
        timeout={timeout} 
      />,
      <div key={'LayoutKey'} className={layoutClass}>
        <Topbar
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
        />
        {/* <Navbar/> */}
        <Sidebar
          sidebarShow={sidebarShow}
          sidebarCollapse={sidebarCollapse}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          sicSurveyUrl={sicSurveyUrl}
        />
        
        {/* {showSurveyPopup &&
          // sicSurveyPopup={this.state.sicSurveyPopup}
          <Modal 
            isOpen={true} 
            scrollable={false}
            className='sic-survey-modal'>
            <ModalHeader>Tell us about your experience with the&nbsp;Intelligence&nbsp;Center!<button className="close sic-survey-close" onClick={this.hideSurveyModal} type="button">&times;</button></ModalHeader>
            <ModalBody>
              <p>Is the Intelligence Center helping you focus on your best planned giving prospects? What could be better? Tell us in our short survey. Your perspectives will help shape future enhancements to best suit your&nbsp;needs.</p>
              <p><a className='btn btn-primary' href={sicSurveyUrl} target='_blank' rel='noreferrer' onClick={this.hideSurveyModal}>Share My Feedback</a></p>
              <p>Short on time? You can take the survey later by clicking the “Feedback” link in the side menu.</p>
            </ModalBody>
          </Modal>
        } */}
      </div>,
      <IdleModal 
        key={'IdleModalKey'}
        showModal={this.state.showModal} 
        handleClose={this.handleClose}
        handleLogout={this.handleLogout}
        warningTimeout={this.state.warningTimeout}
      />
    ];
  }
}

export default withRouter(Layout);