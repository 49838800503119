import React, { useEffect, useState } from 'react';
// import { store } from '../../App/store';
import { Container, Row, Col } from 'reactstrap';
import PageTooltip from '../../../shared/PageTooltip';
// import HomeIntroCard from './components/HomeIntro';
// import HomeStelterContactsCard from './components/HomeStelterContacts';
// import HomeResourcesCard from './components/HomeResources';
// import HomeUsersCards from './components/HomeUsers';
import HomeContent from './components/HomeContent';
import Cookies from 'universal-cookie';
var cookies = new Cookies();

const Page = () => {
  const [refreshTime, setRefreshTime] = useState(0);

  useEffect(() => {
    if(typeof cookies.get('demo') !== 'undefined') {
      cookies.remove('demo', {path: '/', secure: true});
    }
  }, []);
  
  return (
    <div className="container__wrap">
      <Container className="dashboard">
        <PageTooltip state={{ refreshTime: [refreshTime, setRefreshTime] }}/>
        <Row className="row__dashboard-intro">
          <Col md={12} className="dashboard-intro">
            <h3 className="page-title dashboard-title">Program Snapshot</h3>
            <p className="dashboard-subtitle">Review this 90-day look at your planned giving program&rsquo;s metrics. Use the links in the left menu to dig into each category.</p>
          </Col>
        </Row>
        <Row>
          <HomeContent key={refreshTime} />
        </Row>
      </Container>
    </div>
  )
  
};

export default Page;
