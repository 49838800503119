import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Content from './components/ProofsContent';

const Page = () => (
  <div className="container__wrap">
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Proofs</h3>
        </Col>
      </Row>
      <Row>
        <Content />
      </Row>
    </Container>
  </div>
);

export default Page;
