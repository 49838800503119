import React, { PureComponent } from 'react';
import { store } from '../../../App/store';
import { Card, Col } from 'reactstrap';
import LoadingIcon from '../../../../shared/LoadingIcon';
import { fetchEmbed } from '../../../../utils/helpers';
const embedItem = 12;
let srcDoc = '';

class Content extends PureComponent {
  static contextType = store;

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      iframeHeight: '100%',
      containerHeight: 'calc(100vh - 150px)',
      demo: false,
      query: null
    }
    this.containerRef = React.createRef();
  }

  componentDidMount = async () => {
    const globalState = this.context;
    const { userGroup, accountID } = globalState.state;
    const isAdmin = userGroup === 'Admin';
    const position = this.containerRef.current.getBoundingClientRect();
    const topPosition = position.top;

    const queryParams = new URLSearchParams(window.location.search);

    this.setState({
      loading: true,
      containerHeight: `calc(100vh - ${topPosition}px)`,
      query: queryParams
    });

    let pFilters = JSON.stringify([
      {
        column:"Account ID",
        operand: "IN",
        values: [accountID]
      }
    ]);

    if (isAdmin) console.log('pFilters = '+ pFilters)

    this.fetchEmbed = fetchEmbed.bind(this);
    srcDoc = await this.fetchEmbed(embedItem, pFilters, isAdmin);

    this.setState({ // prevState - previous state 
      loading: false, 
      loaded: true
    });

    // style={{height: this.state.iframeHeight}}
  }

  render(){
    const globalState = this.context;
    const { accountName, legacySurveyClient } = globalState.state;
    const demo = accountName === 'Utopia University';

    const legacySurveyAccess = legacySurveyClient.toString() === "true";

    return (
      <Col md={12}>
          {(legacySurveyAccess || demo) ? (
              <Card>
                <LoadingIcon />
                <div id="iframe-container" className="iframe-container" ref={this.containerRef} style={{height: this.state.containerHeight}}>
                  <div className="iframe-wrapper" >
                    {this.state.loaded ? (
                      <iframe className="iframe__survey" title="Survey embed" id="iframe" src="" srcDoc={srcDoc} width="100%" height="100%" scrolling="no"></iframe>
                    ) : null}
                  </div>
                </div>
              </Card>
            ) : 
              <Card>
                <div id="iframe-container" className="iframe-container" style={{height: '300px'}}>
                  <div className="iframe-wrapper" style={{height: '300px'}}>
                    <p style={{paddingLeft: '8px'}}>Survey results coming soon!</p>
                  </div>
                </div>
              </Card>
            }
      </Col>
    )
}}
  
  export default Content;
