import React, { PureComponent } from 'react';
import { Col, Card } from 'reactstrap';
import LoadingIcon from '../../../../shared/LoadingIcon';
let srcDoc = window.location.hostname === 'intelligence.stelter.com' ? 'https://docs.google.com/document/d/1KErOTxrsltwVBEhOzM29YYEhko9N3nqyS5NsmNrDlYM/preview' : 'https://docs.google.com/document/d/19frXoi2_F-FgWdu_n7WXz9DT_DSlKovir_1AYgiNCl8/preview';

class MainCard extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      iframeHeight: '100%',
      containerHeight: 'calc(100vh - 150px)'
    }
    this.containerRef = React.createRef();
  }
    
  componentDidMount = async () => {
    const position = this.containerRef.current.getBoundingClientRect();
    const topPosition = position.top;
    this.setState({
      loading: false, 
      loaded: true,
      containerHeight: `calc(100vh - ${topPosition}px)`
    });
  }

  render(){
    return (
      <Col md={12}>
        <Card>
            <LoadingIcon />
            <div id="iframe-container" className="iframe-container" ref={this.containerRef} style={{height: this.state.containerHeight}}>
              <div className="iframe-wrapper" style={{height: this.state.iframeHeight}}>
                {this.state.loaded ? (
                  <iframe className="iframe__glossary" title="Glossary embed" id="iframe" src={srcDoc} width="100%" height="100%" scrolling="yes"></iframe>
                ) : null}
              </div>
            </div>
        </Card>
      </Col>
    )
  }


  // render(){
  //   const { pgWebsite } = this.state;

  //   return (
  //     <Col md={12}>
  //       <Card>
  //         {/* <LoadingIcon /> */}
  //           {/* <div id="iframe-container" className="iframe-container" ref={this.containerRef} style={{height: this.state.containerHeight}}>
  //             <div className="iframe-wrapper" style={{height: this.state.iframeHeight}}>
  //               {this.state.loaded ? (
  //                 <iframe className="iframe__glossary" title="Glossary embed" id="iframe" src="" srcDoc={srcDoc} width="100%" height="100%" scrolling="no"></iframe>
  //               ) : null}
  //             </div>
  //           </div> */}
  //           <div className="non-iframe-container overflow page-copy" ref={this.containerRef} style={{height: this.state.containerHeight}}>
  //             <Card className="top-margin">
  //               <CardBody>
  //                 <h3>Leads</h3>
  //                 <h4>Lead Scoring </h4>
  //                 <p>Helps identify which donors are potentially most interested in planned giving. Activities such as email opens, email clicks, web page views, video views, and form submissions contribute to a person&rsquo;s score. Actions that are further along in a donor&rsquo;s planned giving journey such as form submissions are scored higher than actions towards the beginning, such as opening an email. Future versions will include survey response data.</p>
  //                 <h4>Lead Groups</h4>
  //                 <p>Lead scores are separated into three groups: Hot, the highest scores; Warm, the middle group; and the last group as Cool.</p>
  //               </CardBody>
  //             </Card>
  //             <Card className="top-margin">
  //               <CardBody>
  //                 <h3>Web</h3>
  //                 <h4>Average Time on Site</h4>
  //                 <p>The amount of time on average that users have spent on your site across all visits.</p>
  //                 <h4>Form Submissions</h4>
  //                 <p>A web form collects information such as email address and name to provide access to planned giving brochures, initiate contact with a member of your organization, or register for an event.</p>
  //                 <h4>Page Views</h4>
  //                 <p>A page view occurs when a visitor accesses a page on your website. For example, if a single visitor views 15 pages during a visit, 15 page views are counted. Also, if a visitor views the same page three times during a visit, three page views are counted.</p>
  //                 <h4>Visits</h4>
  //                 <p>A visit is recorded when a person comes to your website. If a person visits the site two times during the timeframe you are viewing, this counts as two visits.</p>
  //                 <h4>Visit Sources</h4>
  //                 <p>A visit source is the campaign or website where the person clicked through to arrive on your planned giving website. For example, if a visitor clicks on a link in a planned giving email, "Email Campaign" will be the visit source. If a person arrives from a Google search link, "Google" will be the visit source. When a person arrives through a link from your main website, "Client Website" will be the visit source. You may also see visit sources labeled as "Typed/Bookmarked" or "Email/Typed/Bookmarked". This means the visitor either directly typed the website address, arrived from a link they bookmarked, or clicked through from an unspecified email campaign.</p>
  //                 <h4>Will my and my team&rsquo;s traffic be tracked?</h4>
  //                 <p>Your traffic is being tracked unless you have provided your IP address to Stelter and are visiting from within your organization&rsquo;s internal network. If you have not shared your IP address with us, please contact your Stelter representative to do so. Stelter&rsquo;s IP address is being excluded.</p>
  //                 {pgWebsite &&
  //                   <p>To help ensure your traffic is blocked from your web analytics moving forward, please visit the <Link to="/app/support/request">support page</Link> and follow the instructions on the "Block Your Traffic" card.</p>
  //                 }
                  
  //               </CardBody>
  //             </Card>
  //             <Card className="top-margin">
  //               <CardBody>
  //                 <h3>Email</h3>
  //                 <h4>Age Segment </h4>
  //                 <p>For educational emails that use age segmentation, there are 5 possible groups: </p>
  //                 <ul>
  //                   <li>A - Recruiting (Ages 40-54)</li>
  //                   <li>B - Transitioning (Ages 55-69)</li>
  //                   <li>C - Motivating (Ages 70+)</li>
  //                   <li>D - Blended (All Ages)</li>
  //                   <li>E - Educating ( Ages Under 40)</li>
  //                 </ul>

  //                 <h4>Email Type</h4>
  //                 <p>Stelter email can generally be categorized as follows: </p>
  //                 <ul>
  //                   <li><strong>Educational:</strong> Educates the reader about your organization and the various ways to provide support</li>
  //                   <li><strong>Journey:</strong> Research-based content supporting the methodology around a planned giving donor journey</li>
  //                   <li><strong>Re-Engagement:</strong> Intended to spark a new open or click after a long period of non-response</li>
  //                   <li><strong>Targeted:</strong> Single-focus email sent to a specific audience</li>
  //                 </ul>

  //                 <h4>Delivery Rate</h4>
  //                 <p>Refers to whether a receiver accepts your email. This is calculated by taking the number of emails delivered divided by the number of emails sent.</p>

  //                 <h4>**NOTE - Impact to Open Rate, Click-to-Open Rate and Unique Opens **</h4>
  //                 <p>On Sept. 20, 2021, Apple released OS upgrades that impact email open rates. Users of Apple&rsquo;s Mail app who have not opted out of the Mail Privacy Protection (MPP) settings will appear to have opened the email and their IP/location will be hidden. Email metrics for open rates and click-to-open rates will be inflated and not represent what the user did. See notes on the Email Analytics page for additional information.</p>

  //                 <h4>Open Rate</h4>
  //                 <p>The percentage of how many recipients opened your email. Open rate only reflects the unique opens, or the distinct number of recipients who have opened your email at least once. This is calculated by taking the unique opens divided by the number of emails delivered.</p>

  //                 <h4>Click-Through Rate (CTR)</h4>
  //                 <p>The percentage of how many recipients clicked on a link. Click-through rate only reflects the unique clicks, or the distinct number of recipients who have clicked on your email at least once. This is calculated by taking the unique clicks divided by the number of emails delivered.</p>

  //                 <h4>Focused Click-Through Rate (CTR)</h4>
  //                 <p>The percentage of recipients who clicked on a link related to the primary content of the email, such as donor stories, educational references and calls to action. Links to social media, email preferences, unsubscribes and view-in-browser links are not included. This provides a more focused look at the content of interest, and is calculated by taking the unique content clicks divided by the number of emails delivered.</p>

  //                 <h4>Unsubscribe Rate</h4>
  //                 <p>The percentage of how many recipients unsubscribed from your email. This is calculated by taking the number of unsubscribes divided by the number of emails delivered.</p>

  //                 <h4>Unique Opens</h4>
  //                 <p>Indicates how many subscribers opened your email, no matter how many times they did it. This represents the distinct number of recipients who have opened your email at least once.</p>

  //                 <h4>Unique Clicks</h4>
  //                 <p>Indicates how many subscribers clicked on a link in your email, no matter how many times they did it. This represents the distinct number of recipients who have clicked on your email at least once.</p>
  //               </CardBody>
  //             </Card>
  //           </div>
  //       </Card>
  //     </Col>
  //   )
  // }
};

export default MainCard;
