import React, { PureComponent } from "react";
import { store } from '../../../App/store';
import { Card, CardBody } from "reactstrap";

class Videos extends PureComponent {
    static contextType = store;
    
    constructor(props){
        super(props);
        this.state = {
            notificationsSupported: true,
            notificationsEnabled: false
        }
    }

    handlePermission(permission) {
        // set the button to shown or hidden, depending on what the user answers
        if(!('Notification' in window)) {
            this.setState({
                notificationsSupported: false,
                notificationsEnabled: false
            })
        } else if (Notification.permission === 'denied' || Notification.permission === 'default') {
            this.setState({notificationsEnabled: false})
        } else {
            this.setState({notificationsEnabled: true})
        }
    }

    checkNotificationPromise() {
        try {
            Notification.requestPermission().then();
        } catch(e) {
            return false;
        }

        return true;
    }

    askNotificationPermission = () => {
        // function to actually ask the permissions
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) {
            console.log("This browser does not support notifications.");
            this.setState({
                notificationsSupported: false,
                notificationsEnabled: false
            })
        } else {
            if(this.checkNotificationPromise()) {
                Notification.requestPermission()
                .then((permission) => {
                    this.handlePermission(permission);
                })
        } else {
            Notification.requestPermission(function(permission) {
                this.handlePermission(permission);
            });
        }
        }
    }

    componentDidMount = () => {
        this.handlePermission()
    }

    handleClick = (e) => {
        this.askNotificationPermission();
    }

    render() {
        const { notificationsSupported, notificationsEnabled } = this.state;

        return (
            <Card className={"top-margin auto-height show-card-" + notificationsSupported}>
                {notificationsSupported &&
                    <CardBody className="card-sm">
                        <p><strong>Desktop Notifications</strong></p>
                        {notificationsEnabled ? (
                            <div>
                                <p>You have enabled desktop notifications for the Intelligence Center. To disable, remove intelligence.stelter.com from your browser&rsquo;s notification settings.</p>
                            </div>
                            ) : (
                            <div>
                                <p>Click to enable desktop notifications to show when you have been idle and are about to be signed out of the Intelligence Center.</p>
                                <p>
                                    <button className="btn btn-primary" style={{marginTop: '10px'}} onClick={() => this.askNotificationPermission()}>Allow Notifications</button>
                                </p>
                            </div>
                            )
                        }
                        
                    </CardBody>
                }
            </Card>
        )
    }
    
};

export default Videos;