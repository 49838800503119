import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StateProvider } from './store.js';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
    window.addEventListener('storage', (event) => {
      if (event.key === 'REQUESTING_SHARED_CREDENTIALS') {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem('SET_SESSION_STORAGE', JSON.stringify(sessionStorage));
      }

      if (event.key === 'SET_SESSION_STORAGE' && localStorage.getItem('SET_SESSION_STORAGE') !== null) {
          var data = JSON.parse(localStorage.getItem('SET_SESSION_STORAGE'));
          Object.keys(data).forEach(key => {
              sessionStorage.setItem(key, data[key]);
          })
        setTimeout(() => {
          localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS')
          localStorage.removeItem('SET_SESSION_STORAGE')
        }, 500)
        
      }

      if(event.key === 'LOGOUT_REQUEST') {
        sessionStorage.clear();
      }

    })

  }

  componentDidMount() {
    localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now().toString())
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;

    return (
      <StateProvider>
        <BrowserRouter>
          <ScrollToTop>
            {!loaded
              && (
              <div className={`load${loading ? '' : ' loaded'}`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#cd3702" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
              )
            }
            <div >
              <Router />
            </div>
          </ScrollToTop>
        </BrowserRouter>
      </StateProvider>
    );
  }
}

export default App;
