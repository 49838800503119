import React, { PureComponent } from 'react';
import { Card, Col } from 'reactstrap';
import API_HOST from '../../../../utils/ApiHost';
const embedItem = 4;
let srcDoc = '';

class Content extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount = () => {
    this.setState({loading:true});

    fetch(`${API_HOST}/api/embed/items/${embedItem}`,{
      headers: {"accessToken":sessionStorage.getItem('domoToken')},
      credentials: 'include'
    }).then(response => {
        return response.text()
      })
      .then(data => {
        srcDoc = data;
      })
      .catch((e) => {
        console.error("An error occurred while processing the request.", e, e.message, e.error);
      })
      .finally(() => {
        this.setState({loading:false});
      });
  }

  render(){
    return (
      <Col md={12}>
        <Card>
          <div id="iframe-container" className="iframe-container">
            <iframe className="iframe__mail" title="Web embed" id="iframe" src="" srcDoc={srcDoc} width="100%" height="100%" scrolling="no"></iframe>
          </div>
        </Card>
      </Col>
    )
}}
  
  export default Content;
