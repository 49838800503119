import React, { PureComponent } from "react";
import { store } from '../../../App/store';
import { Col, Row } from "reactstrap";
import PlayCircleIcon from 'mdi-react/PlayCircleIcon';

class Videos extends PureComponent {
    static contextType = store;
    
    constructor(props){
        super(props);
        this.state = {
            videos: [
                {
                    "id": "530972045",
                    "name": "Home Page",
                    "thumb": "https://i.vimeocdn.com/video/1099611605-7b716c32ce3d4ab8edc9d88673e53ca4bfda11d6840a015ab57b8820220f309b-d?mw=600&mh=338",
                    "runtime": "3:01"
                },
                {
                    "id": "530827297",
                    "name": "Leads Page",
                    "thumb": "https://i.vimeocdn.com/video/1099610419-c6a8c77bbb46ba9205f6fe78a31f25d9f54f18bf5ecc081b71306f78128c210a-d?mw=600&mh=338",
                    "runtime": "2:44"
                },
                {
                    "id": "530824895",
                    "name": "Web Analytics",
                    "thumb": "https://i.vimeocdn.com/video/1099612378-3d5dc926a88e2c4c6f89f198433ff26adf6bbdb2a9085127707677a7488a28ee-d?mw=600&mh=338",
                    "runtime": "3:04"
                },
                {
                    "id": "531260697",
                    "name": "Email Analytics",
                    "thumb": "https://i.vimeocdn.com/video/1099611998-174378c17707a6f0f02e414842ee36672a888d1b6d3b6ed22edd99e6a193dff1-d?mw=600&mh=338",
                    "runtime": "2:59"
                },
                {
                    "id": "530970914",
                    "name": "Navigate & Export",
                    "thumb": "https://i.vimeocdn.com/video/1099611032-a9065b92dad8a0f5355d34f49a21a36d9aa1b481e88e0ad6a2b0638ba69c1e60-d?mw=600&mh=338",
                    "runtime": "1:32"
                },
                {
                    "id": "530969722",
                    "name": "Filter & Sort",
                    "thumb": "https://i.vimeocdn.com/video/1099610748-0952b3175502fbde7e810994746d70dad6e49afd1550a593768cfa7157948a7b-d?mw=600&mh=338",
                    "runtime": "1:13"
                }
            ],
            featuredId: '530972045'
        }
        this.containerRef = React.createRef();
    }

    componentDidMount = () => {
        const globalState = this.context;
        const { accountName, surveyClient } = globalState.state;
        const demo = accountName === 'Utopia University' || (process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV !== 'prod');

        const surveyAccess = surveyClient.toString() === 'true';
        const surveyVideoObj = {
            "id": "552407972",
            "name": "Survey Analytics",
            "thumb": "https://i.vimeocdn.com/video/1141342939-b46ed816d78dc394d8b0f100523b216bf0e75efe9e579db7e27c48fdc0a38dd1-d?mw=600&mh=338",
            "runtime": "9:44"
        };
        if (demo || surveyAccess) {
            var videosArr = this.state.videos;
            videosArr.splice(4, 0, surveyVideoObj);
            this.setState({ videos: videosArr })
        }
    }

    handleClick = (e) => {
        let targetId = e.currentTarget.id;
        this.setState({featuredId: targetId})
    }

    handleSlide = (e) => {
        var targetId = e.currentTarget.id;
        let videos = this.state.videos;
        let indexLength = videos.length - 1;
        let featuredId = this.state.featuredId;
        let currentIndex = videos.findIndex((video,index) => video.id === featuredId);
        var newIndex = currentIndex;
        if(targetId === 'video-prev-btn') {
            if (currentIndex !== 0) {
                newIndex = currentIndex - 1;
            } else {
                newIndex = indexLength;
            }
        } else {
            if (currentIndex !== indexLength) {
                newIndex = currentIndex + 1;
            } else {
                newIndex = 0;
            }
        }
        var newFeaturedId = videos[newIndex].id;
        this.setState({featuredId: newFeaturedId})
    }

    render() {
        const { videos, featuredId } = this.state;

        return (
            <Row>
                <Col sm={12} className="featured-video-container">
                    <div className="featured-video-wrapper">
                        <div className="iframe-video-wrapper">
                            <iframe id="featured-video" className="iframe-video" title="Featured Video" src={'https://player.vimeo.com/video/' + featuredId} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className="featured-video-controls">
                        <span id="video-prev-btn" className="video-control-btn video-prev-btn" onClick={this.handleSlide}><span className="lnr lnr-chevron-left"><span className="sr-only">previous video</span></span></span>
                        <span id="video-next-btn" className="video-control-btn video-next-btn" onClick={this.handleSlide}><span className="lnr lnr-chevron-right"><span className="sr-only">next video</span></span></span>
                    </div>
                </Col>
                {
                    videos.map((video, index) => {
                        var featuredClass = video.id === featuredId ? 'featured' : '';
                        return (
                            <Col key={video.id + "_" + index} sm={6} md={4} xl={3}>
                                <div id={video.id} className={"video-poster-wrapper " + featuredClass} onClick={this.handleClick} tabIndex="0">
                                    <span className="video-play-icon"><PlayCircleIcon /></span>
                                    <span className="video-runtime">{video.runtime}</span>
                                    <img className="video-poster-sample" src={video.thumb} width="640" height="360" alt={video.name + " video"} />
                                </div>
                            </Col>
                        )
                    })
                }

            </Row>
        )
    }
    
};

export default Videos;