import React , { useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { store } from '../App/store';
import API_HOST from '../../utils/ApiHost';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const logoutFromAzure = async () => {
    let response = await fetch(`${API_HOST}/api/logout`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    .catch((err) => {
      console.error(err);
    });
    let data = await response.json();

    //Redirect to azure logout
    if(data.logoutURL) {
      window.location.href = data.logoutURL;
    }
}

const Logout = () => {
  const history = useHistory();
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { isAzureLogin, isAuthenticated, userGroup } = globalState.state;
  const isAdmin = userGroup === 'Admin';
  const authLocal = sessionStorage.getItem("authLocal") !== null;

  useEffect(() => {
    if(authLocal || isAuthenticated.toString() === 'false') {
      setTimeout(() => {
        // return <Redirect to="/login" />;
        history.push('/login')
      }, 2000);
    }

    if(isAuthenticated.toString() === 'true') {
      console.log('isAzureLogin is ' + isAzureLogin)
      sessionStorage.clear();

      const allCookies = cookies.getAll();
      Object.keys(allCookies).forEach(key => {
        cookies.remove(key, {path: '/', secure: true})
      })

      // clear sessionStorage from other tabs
      localStorage.setItem('LOGOUT_REQUEST', Date.now().toString());
      localStorage.clear();

      dispatch({ type: 'SET_USER', value: ''});
      dispatch({ type: 'SET_USER_AUTH', value: false});
      dispatch({ type: 'SET_EMBED_TOKEN', value: ''});
      dispatch({ type: 'SET_AZURE_LOGIN', value: false});
      dispatch({ type: 'SET_ACCOUNT_ID', value: '' });
      dispatch({ type: 'SET_ACCOUNT_NAME', value: '' });
      dispatch({ type: 'SET_AUTH_REDIRECT', value: '' });
      dispatch({ type: 'SET_PG_WEBSITE', value: '' });
      dispatch({ type: 'SET_WEB_CLIENT', value: false });
      dispatch({ type: 'SET_EMAIL_CLIENT', value: false });
      dispatch({ type: 'SET_SURVEY_CLIENT', value: false });
      dispatch({ type: 'SET_LEGACY_SURVEY_CLIENT', value: false });
      dispatch({ type: 'SET_GIVING_DOCS_CLIENT', value: false });
      dispatch({ type: 'SET_PLATFORM_CLIENT', value: false });
      dispatch({ type: 'SET_LEADS_DATA', value: '' });
      dispatch({ type: 'SET_LEADS_SUMMARY', value: '' });
      dispatch({ type: 'SET_LEADS_ACTIVITIES', value: '' });
      dispatch({ type: 'SET_TOTAL_LEADS', value: 0 });

      if (isAdmin) console.log('delete db')
      const dbDelete = indexedDB.deleteDatabase('Leads');
      dbDelete.onerror = (event) => {
          if (isAdmin) {
              console.error("Error deleting database.");
          }
      };
      dbDelete.onsuccess = (event) => {
          if (isAdmin) {
              console.log("Database deleted successfully");
          }
      };

      logoutFromAzure();
    }
  }, [dispatch, history, isAzureLogin, isAuthenticated, authLocal, isAdmin]);

  return (
    <div className={`load`}>
      <div className="load__icon-wrap">
        <svg className="load__icon">
          <path fill="#cd3702" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
    </div>
  )
};

export default Logout;