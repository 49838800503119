import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import { store } from '../../App/store';
import SupportVideos from "./components/SupportVideos";
import Form from "./components/SupportForm";
import DomoStatus from "./components/DomoStatus";
import BlockTracking from "./components/BlockTracking";
import AllowNotifications from "./components/AllowNotifications";

export default function Page({title}) {
  const globalState = useContext(store);
  const { pgWebsite, userGroup } = globalState.state;

  return (
    <div className="container__wrap allow-overflow">
      <Container className="dashboard">
        <Row className="row__dashboard-intro">
          <Col>
            <h2 className="page-title">{title}</h2>
          </Col>
        </Row>
        <div className="non-iframe-container">
          {title.toLowerCase().includes('video') ? (
            <SupportVideos />
          ) :
            <Row>
              <Col md={6} >
                <Form />
              </Col>
              <Col md={6} lg={{ size: 4, offset: 2 }}>
                <DomoStatus />
                {pgWebsite !== '' &&
                  <BlockTracking usergroup={userGroup} pgwebsite={pgWebsite} />
                }
                <AllowNotifications />
              </Col>
            </Row>
          }
        </div>
      </Container>
    </div>
  )
}
