import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import API_HOST from '../../utils/ApiHost';

async function getRemainingTime() {
  let currentTime = new Date();
  let startLoginTime = new Date(sessionStorage.getItem('startLoginTime'));
  let elapsedTime = (currentTime - startLoginTime) / 1000;

  //Create new domo token when authenticated and is expired
  if (sessionStorage.getItem('isAuthenticated') && (elapsedTime >= 3600)) { //3600ms = 1 hour
    //console.log('Expired token');
    const domoTokenResp = await fetch(`${API_HOST}/api/refreshDomoToken`, {
       method: 'post',
       headers: {
        'Content-Type': 'application/json',
      },
       credentials: 'include'
    });
    //console.log('domoTokenResp: ', domoTokenResp);
    var json = await domoTokenResp.json();
    //console.log('JSON: ', json);
    //set new startLoginTime and domoToken in sessionStorage
    if (json.state === 'success') {
       sessionStorage.setItem('startLoginTime', new Date());
       sessionStorage.setItem('domoToken', json.domoToken);
       window.domoToken = json.domoToken;
       window.__RYUU_AUTHENTICATION_TOKEN__ = json.domoToken;
    }
    else{
      console.log('Failed to generate new data server token');
    }
  }
  else if (sessionStorage.getItem('isAuthenticated')) {
    //console.log('Active domoToken');
  }
  return sessionStorage.getItem('startLoginTime');
}

class MainWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startLoginTime: null
    };
  }

  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  async checkSession() {
    var cookies = new Cookies();
    cookies.set('active','true', {path: '/', maxAge: 3600, secure: true, sameSite: 'strict'})
    let startLoginTimeRes = await getRemainingTime();
    this.setState({startLoginTime: startLoginTimeRes});
  };

  componentDidMount() {
    this.setState({startLoginTime: sessionStorage.getItem('startLoginTime')});
  }

  render() {
    const { children } = this.props;

    return (
      <div className="theme-light">
        <div className="wrapper" onClick={async () => {await this.checkSession();} }>
          {children}
        </div>
      </div>
    );
  }
}

export default MainWrapper;
